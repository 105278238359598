import React, { type ReactElement, useContext, useState } from 'react';
import Card from '../../../../molecules/Card/Card.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import ConsentExpiry from '../../../../organisms/ConsentExpiry/ConsentExpiry.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import PlatformTermsCheckbox from '../../../../organisms/PlatformTermsCheckbox/PlatformTermsCheckbox.tsx';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';
import Loading from '../../../../atoms/Loading/Loading.tsx';
import PrivacyText from '../../../../atoms/PrivacyText/PrivacyText.tsx';
import API from '../../../../../api/API.ts';
import AppContext from '../../../../../store/AppContext.ts';
import Notice, { NoticeType } from '../../../../atoms/Notice/Notice.tsx';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { AccountModel } from '../../../../../types/account.types.ts';
import { useTranslation } from 'react-i18next';
import CollapsibleNotice from '../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';

interface DigitalConsentProps {
  consent: HostedConsentModel;
  onNext: () => void;
  onBack: () => void;
  termsChecked: boolean;
  isThirdParty: boolean;
  code: string | null;
  account?: AccountModel;
}

function DigitalConsent({
  consent,
  termsChecked,
  isThirdParty,
  code,
  onNext,
  onBack,
  account,
}: DigitalConsentProps): ReactElement {
  const { t } = useTranslation('uk-flow');
  const [name, setName] = useState(consent.consentee_full_name ?? '');
  const [jobTitle, setJobTitle] = useState(consent.consentee_job_title ?? '');
  const [email, setEmail] = useState(consent.consentee_email ?? '');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  const { ConsentStore } = useContext(AppContext);

  /**
   * Attempt to verify a consent if we already have a code
   */
  async function handleVerifyConsent(): Promise<void> {
    if (code != null) {
      setLoading(true);
      await API.axios
        .post<{ success: boolean; data: HostedConsentModel }>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/verify`,
          {
            code,
            hosted_consent_version: APP_VERSION,
          },
        )
        .catch(error => {
          setError(error.response.data.errors[0].message);
          setLoading(false);
        });
    }
  }

  function handleChangeName(e: React.ChangeEvent<HTMLInputElement>): void {
    setName(e.target.value);
  }

  function handleChangeJobTitle(e: React.ChangeEvent<HTMLInputElement>): void {
    setJobTitle(e.target.value);
  }

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }

  async function handleSignConsent(): Promise<void> {
    setLoading(true);
    setError(null);
    await API.axios
      .put<{ success: boolean; data: HostedConsentModel }>(
        `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}`,
        {
          consentee_full_name: name,
          consentee_job_title: jobTitle,
          consentee_email: email,
        },
      )
      .then(res => {
        ConsentStore.setConsent(res.data.data);
      })
      .catch(error => {
        setError(error.response.data.errors[0].message);
        setLoading(false);
      });
    /**
     * If we are a commercial consent, we should "approve" this consent
     */
    if (consent.flow_type.includes('commercial')) {
      await API.axios
        .post<{ success: boolean; data: HostedConsentModel }>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/approve`,
          {
            hosted_consent_version: APP_VERSION,
          },
        )
        .catch(error => {
          setError(error.response.data.errors[0].message);
          setLoading(false);
        });
    }
    /**
     * If we are a third party, we also need to validate the email.
     */
    if (isThirdParty) {
      await handleVerifyConsent();
    }

    onNext();
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Card account={account}>
      <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.LG}>
        <Heading>
          {consent.flow_type.includes('commercial') ? (
            <>{t('commercialBlanketFlow.methods.digital.headingCommercial')}</>
          ) : (
            <>{t('commercialBlanketFlow.methods.digital.headingResidential')}</>
          )}
        </Heading>
        <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD} align={Align.STRETCH}>
          <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD} align={Align.STRETCH}>
            <TextInput
              label={t('common:fullName')}
              value={name}
              onChange={handleChangeName}
              disabled={
                consent.consentee_full_name ? consent.consentee_full_name.length > 0 : false
              }
            />
            <TextInput
              label={t('common:jobTitle')}
              value={jobTitle}
              onChange={handleChangeJobTitle}
              disabled={
                consent.consentee_job_title ? consent.consentee_job_title.length > 0 : false
              }
            />
            <TextInput
              label={t('common:email')}
              value={email}
              onChange={handleChangeEmail}
              disabled={consent.consentee_email ? consent.consentee_email.length > 0 : false}
            />
          </Flex>
          <ConsentExpiry consent={consent} />
        </Flex>
        <CollapsibleNotice
          title={t('commercialBlanketFlow.methods.digital.termsHeading')}
          iconOverride={<PlatformTermsCheckbox noContent />}>
          <>
            {t('commercialBlanketFlow.methods.digital.termsText', {
              partner: consent.account.name,
              customer: consent.customer.name,
            })}
          </>
        </CollapsibleNotice>
        {error != null && <Notice type={NoticeType.ERROR}>{error}</Notice>}
        <Flex gap={Gap.LG}>
          {!isThirdParty && (
            <Button
              outline
              onClick={() => {
                onBack();
              }}>
              {t('common:back')}
            </Button>
          )}
          <Button
            disabled={!termsChecked || name.length === 0 || jobTitle.length === 0}
            onClick={handleSignConsent}>
            {t('common:next')}
          </Button>
        </Flex>
        <PrivacyText />
      </Flex>
    </Card>
  );
}

export default DigitalConsent;
