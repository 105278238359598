import { type ReactElement, useState } from 'react';
import Card from '../../../../molecules/Card/Card.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../atoms/Muted/Muted.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import RequestThirdPartyConsent from '../RequestThirdPartyConsent/RequestThirdPartyConsent.tsx';
import css from '../../../../molecules/EmailVerificationScreen/EmailVerificationScreen.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import Button from '../../../../atoms/Button/Button.tsx';
import navigateToCallback from '../../../../../utils/navigateToCallback.ts';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { AccountModel } from '../../../../../types/account.types.ts';
import { useTranslation } from 'react-i18next';

interface ThirdPartyConsentScreenProps {
  onBack: () => void;
  consent: HostedConsentModel;
  partner: string;
  account?: AccountModel;
}

enum ThirdPartyConsentScreenSteps {
  REQUEST = 'request',
  SENT = 'sent',
}
function ThirdPartyConsentScreen({
  onBack,
  consent,
  partner,
  account,
}: ThirdPartyConsentScreenProps): ReactElement {
  const { t } = useTranslation('uk-flow');
  const [step, setStep] = useState(ThirdPartyConsentScreenSteps.REQUEST);

  function render(): ReactElement {
    switch (step) {
      case ThirdPartyConsentScreenSteps.REQUEST:
        return (
          <>
            <Flex gap={Gap.XS} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
              <Heading>{t('commercialBlanketFlow.methods.thirdParty.heading')}</Heading>
              <Muted>{t('commercialBlanketFlow.methods.thirdParty.share')}</Muted>
            </Flex>
            <RequestThirdPartyConsent
              onCancel={() => {
                onBack();
              }}
              onComplete={() => {
                setStep(ThirdPartyConsentScreenSteps.SENT);
              }}
              consent={consent}
            />
          </>
        );
      case ThirdPartyConsentScreenSteps.SENT:
        return (
          <>
            <Flex gap={Gap.XS} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
              <Heading>
                {t('commercialBlanketFlow.methods.thirdParty.sentBlanket', {
                  consentee_email: consent.consentee_email,
                })}
              </Heading>
              <Muted>{t('commercialBlanketFlow.methods.thirdParty.sentPersonal')}</Muted>
            </Flex>
            <Button
              className={css.fullWidth}
              onClick={() => {
                navigateToCallback(consent);
              }}>
              {t('common:returnToApp', { account: partner })}
            </Button>
          </>
        );
    }
  }
  return (
    <Card account={account}>
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          className={css.animated}
          initial={{
            opacity: 0,
            transform: 'translateX(20px)',
          }}
          animate={{
            opacity: 1,
            transform: 'translateX(0px)',
          }}
          exit={{
            opacity: 0,
            transform: 'translateX(-20px)',
          }}
          transition={{
            duration: 0.5,
          }}>
          <Flex gap={Gap.XL} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
            {render()}
          </Flex>
        </motion.div>
      </AnimatePresence>
    </Card>
  );
}

export default ThirdPartyConsentScreen;
