import { type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FileMetadata } from '../../../types/common.types.ts';
import Button from '../../atoms/Button/Button.tsx';
import Flex, { FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Card from '../Card/Card.tsx';
import css from './StartScreen.module.scss';

export interface StartScreenProps {
  onNext: () => void;
  partner: string;
  logo: string | null;
  logoMetadata: FileMetadata | null;
}

function StartScreen({ onNext, partner, logo, logoMetadata }: StartScreenProps): ReactElement {
  function handleNext(): void {
    onNext();
  }
  const { t } = useTranslation();

  return (
    <Card>
      <Flex className={css.startScreen} flexDirection={FlexDirection.COLUMN} gap={Gap.LG}>
        <div className={css.imageGrid}>
          {logo != null && logoMetadata != null ? (
            <img crossOrigin="anonymous" className={css.logo} src={logo} alt="File Preview" />
          ) : (
            <img className={css.logo} src="/logo-full.svg" alt="Openvolt" />
          )}
          <img src="/connector.svg" />
          <img src="/meters.svg" />
        </div>
        <Heading>{t('common:consentStartScreen.heading', { partner })}</Heading>
        <Button onClick={handleNext}>{t('common:getStarted')}</Button>
      </Flex>
    </Card>
  );
}

export default StartScreen;
