import { useContext, useRef, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API, { APIResponse } from '../../../../../api/API.ts';
import AppContext from '../../../../../store/AppContext.ts';
import {
  PostcoderAutocompleteResult,
  UKPostcoderSearchResult,
} from '../../../../../types/common.types.ts';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { useClickOutsideRef } from '../../../../../utils/useClickOutsideRef.ts';
import { DataFetching } from '../../../../animations/DataFetching/DataFetching.tsx';
import FadeIn from '../../../../animations/FadeIn/FadeIn.tsx';
import { ScreenTransition } from '../../../../animations/ScreenTransition/ScreenTransition.tsx';
import { AddressBox } from '../../../../atoms/AddressBox/AddressBox.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import { ErrorNotice } from '../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../atoms/Muted/Muted.tsx';
import { SearchInput } from '../../../../atoms/SearchInput/SearchInput.tsx';
import { SelectInput } from '../../../../atoms/SelectInput/SelectInput.tsx';
import Card from '../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { ResultsBox } from '../../../../molecules/ResultsBox/ResultsBox.tsx';
import { GBResidentialSpecificConsentSteps } from '../../gb.residential.specific.tsx';
import { SearchForMPAN } from '../SearchForMPAN/SearchForMPAN.tsx';
import css from './MPANCollection.module.scss';

const occupationMonthOptions = [
  { value: '12', text: '12 Months' },
  { value: '11', text: '11 Months' },
  { value: '10', text: '10 Months' },
  { value: '9', text: '9 Months' },
  { value: '8', text: '8 Months' },
  { value: '7', text: '7 Months' },
  { value: '6', text: '6 Months' },
  { value: '5', text: '5 Months' },
  { value: '4', text: '4 Months' },
  { value: '3', text: '3 Months' },
  { value: '2', text: '2 Months' },
  { value: '1', text: '1 Month' },
];

enum MPANCollectionSteps {
  ADDRESS_COLLECTION = 'address_collection',
  OCCUPATION_PERIOD_COLLECTION = 'occupation_period_collection',
  MPAN_COLLECTION = 'mpan_collection',
}

interface MPANCollectionProps {
  consent: HostedConsentModel;
  onNext: () => void;
}

function MPANCollection({ consent, onNext }: MPANCollectionProps): ReactElement {
  const { t } = useTranslation('uk-flow');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<null | string | string[]>(null);
  const [addressResults, setAddressResults] = useState<PostcoderAutocompleteResult[]>([]);
  const [addressResultsOpen, setAddressResultsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const [step, setStep] = useState(MPANCollectionSteps.ADDRESS_COLLECTION);

  const occupationPeriodOptions = {
    THIRTEEN_MONTHS_OR_MORE: t('common:occupationPeriod.more13'),
    LESS_THAN_THIRTEEN_MONTHS: t('common:occupationPeriod.less13'),
  } as const;
  type OccupationPeriodOptions =
    (typeof occupationPeriodOptions)[keyof typeof occupationPeriodOptions];

  /**
   * Stuff the user provides or clicks on.
   */
  const [selectedAddress, setSelectedAddress] = useState<UKPostcoderSearchResult | null>(null);
  const [selectedOccupationPeriod, setSelectedOccupationPeriod] = useState<string | null>(null);
  const [occupationMonths, setOccupationMonths] = useState<string | undefined>(undefined);

  const postcodeSearchRef = useRef(null);

  const { ConsentStore } = useContext(AppContext);

  /**
   * Handle searching for a postcode on the API and putting the results of that call into state
   * @param postcode
   */
  async function handleSearch(query: string): Promise<void> {
    setSearchQuery(query);
    if (query.length > 0) {
      setErrors(null);
      setLoading(true);
      setAddressResultsOpen(false);
      await API.axios
        .get<APIResponse>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/autocomplete-search`,
          {
            params: {
              query,
              country: 'UK',
            },
          },
        )
        .then(({ data }) => {
          if (data.success) {
            setAddressResults(data.data);
            setAddressResultsOpen(true);
          }
        })
        .catch(({ response }) => {
          setErrors(response.data.errors);
          setAddressResults([]);
          setAddressResultsOpen(false);
        });

      setLoading(false);
    }
  }

  async function handleRetrieveAutocomplete(id: string): Promise<void> {
    try {
      setErrors(null);
      setLoading(true);
      setAddressResultsOpen(false);
      const { data } = await API.axios.get<APIResponse>(
        `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/autocomplete-retrieve`,
        {
          params: {
            id,
            query: searchQuery,
            country: 'UK',
          },
        },
      );
      await handleSelectAddress(data.data);
      setLoading(false);
    } catch (e: any) {
      setErrors(e);
    }
  }

  async function handleSelectAddress(address: UKPostcoderSearchResult): Promise<void> {
    try {
      setSelectedAddress(address);
      setAddressResultsOpen(false);
      const res = await API.updateConsent(consent.id, {
        contextual_data: {
          ...consent.contextual_data,
          address: address.summaryline,
          address_identifier: address.premise
            .split(',')
            .map(s => s.trim())
            .join(','),
          postcode: address.postcode,
        },
      });
      ConsentStore.setConsent(res.data.data);
    } catch (e: any) {
      setErrors(e);
    }
  }

  async function handleSelectOccupationPeriod(
    occupationPeriod: OccupationPeriodOptions,
  ): Promise<void> {
    if (occupationPeriod === occupationPeriodOptions.THIRTEEN_MONTHS_OR_MORE) {
      setStep(MPANCollectionSteps.MPAN_COLLECTION);
      await handleUpdateOccupationMonths('>13');
    } else {
      setSelectedOccupationPeriod(occupationPeriod);
    }
  }

  async function handleUpdateOccupationMonths(months: string): Promise<void> {
    setOccupationMonths(months);
    try {
      const res = await API.updateConsent(consent.id, {
        contextual_data: { ...consent.contextual_data, occupation_months: months },
      });
      ConsentStore.setConsent(res.data.data);
    } catch (e: any) {
      setErrors(e);
    }
  }

  async function handleUpdateMeterNumber(mpans: {
    electricity: string | undefined;
    gas: string | undefined;
  }): Promise<void> {
    const mpansToSave = Object.values(mpans).filter(Boolean) as string[];
    try {
      const res = await API.updateConsent(consent.id, {
        meter_numbers: consent.meter_numbers
          ? [...new Set([...consent.meter_numbers, ...mpansToSave])]
          : mpansToSave,

        contextual_data: { ...consent.contextual_data, found_meter_numbers: mpans },
      });
      ConsentStore.setConsent(res.data.data);
      onNext();
    } catch (e: any) {
      setErrors(e);
    }
  }

  /**
   * If we click outside the postcode search, we should close the form.
   */
  useClickOutsideRef(postcodeSearchRef, () => {
    setAddressResultsOpen(false);
  });

  function renderStep(): ReactElement {
    switch (step) {
      case MPANCollectionSteps.ADDRESS_COLLECTION:
        return (
          <Flex
            className={css.addressCollection}
            gap={Gap.LG}
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}>
            <Heading>{t('mpanCollectionScreen.heading')}</Heading>
            {errors != null && (
              <ErrorNotice errors="There was an error whilst searching for this address" />
            )}
            <div className={css.postcodeSearch} ref={postcodeSearchRef}>
              <SearchInput
                onSearch={handleSearch}
                label={t('common:searchQuery')}
                loading={loading}
                onClick={() => {
                  setAddressResultsOpen(true);
                }}
              />
              {addressResultsOpen && (
                <ResultsBox className={css.results}>
                  {addressResults.length === 0 && <Muted>{t('common:noResults')}</Muted>}
                  {addressResults.map((result, i) => {
                    return (
                      <div
                        key={i}
                        className={css.result}
                        onClick={async () => {
                          await handleRetrieveAutocomplete(result.id);
                        }}>
                        {result.summaryline} {result.locationsummary}
                      </div>
                    );
                  })}
                </ResultsBox>
              )}
            </div>
            {selectedAddress != null && (
              <>
                <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.SM}>
                  <Heading>{t('mpanCollectionScreen.addressConfirm')}</Heading>
                  <AddressBox address={selectedAddress.summaryline} />
                </Flex>
                <Button
                  className={css.next}
                  onClick={() => {
                    setStep(MPANCollectionSteps.OCCUPATION_PERIOD_COLLECTION);
                  }}>
                  {t('common:yesContinue')}
                </Button>
              </>
            )}
          </Flex>
        );
      case MPANCollectionSteps.OCCUPATION_PERIOD_COLLECTION:
        if (selectedAddress == null) {
          // not translated because it shouldn't happen
          throw Error('Got to occupation period collection screen with no address.');
        }
        return (
          <Flex
            className={css.occupationPeriodCollection}
            gap={Gap.LG}
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}>
            <AddressBox
              address={selectedAddress.summaryline}
              onChange={() => {
                setStep(MPANCollectionSteps.ADDRESS_COLLECTION);
              }}
            />
            <Heading>{t('mpanCollectionScreen.occupationPeriodHeading')}</Heading>
            <Flex className={css.occupationPeriodOptions} gap={Gap.MD}>
              {Object.values(occupationPeriodOptions).map((period, i) => {
                const isDisabled =
                  selectedOccupationPeriod != null && selectedOccupationPeriod !== period;
                const isSelected =
                  selectedOccupationPeriod != null && selectedOccupationPeriod === period;
                return (
                  <div
                    className={`${css.option} ${
                      isDisabled ? css.disabled : ''
                    } ${isSelected ? css.selected : ''}`}
                    key={i}
                    onClick={async () => {
                      await handleSelectOccupationPeriod(period);
                    }}>
                    {period}
                  </div>
                );
              })}
            </Flex>
            {selectedOccupationPeriod != null && (
              <FadeIn>
                <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.LG}>
                  <div>
                    <Heading>{t('mpanCollectionScreen.occupationPeriodSubheading')}</Heading>
                    <Muted>{t('mpanCollectionScreen.occupationPeriodDescription')}</Muted>
                  </div>
                  <SelectInput
                    value={occupationMonths}
                    options={occupationMonthOptions}
                    onChange={async value => {
                      if (value) {
                        await handleUpdateOccupationMonths(value);
                      }
                    }}
                  />
                  <Button
                    className={css.next}
                    disabled={occupationMonths === null}
                    onClick={() => {
                      setStep(MPANCollectionSteps.MPAN_COLLECTION);
                    }}>
                    Next
                  </Button>
                </Flex>
              </FadeIn>
            )}
          </Flex>
        );
      case MPANCollectionSteps.MPAN_COLLECTION:
        return (
          <SearchForMPAN
            address={selectedAddress}
            consent={consent}
            onChangeAddress={() => {
              setStep(MPANCollectionSteps.ADDRESS_COLLECTION);
            }}
            onSelectMPAN={async mpans => {
              await handleUpdateMeterNumber(mpans);
            }}
          />
        );
      default:
        return <DataFetching text="Searching for MPAN" />;
    }
  }

  return (
    <Card account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <ConsentSteps
          steps={GBResidentialSpecificConsentSteps}
          current={GBResidentialSpecificConsentSteps[0]}
        />
        <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
      </Flex>
    </Card>
  );
}

export default MPANCollection;
