import { HostedConsentModel } from '../../../../../types/hosted-consent.types';

export enum AvailableNLCommercialOperators {
  ANEXO = 'anexo',
  EDSN = 'edsn',
  FUDURA = 'fudura',
  MEETBEDRIJF = 'meetbedrijf',
  INNAX = 'innax',
  JOULZ = 'joulz',
  KENTER = 'kenter',
  TUMS = 'tums',
}

export interface OperatorSpecificFlowProps {
  onNext: () => void;
  onBackToOperatorSelector: () => void;
  consent: HostedConsentModel;
}
