import { type PropsWithChildren, type ReactElement } from 'react';
import css from './Muted.module.scss';

interface MutedProps {
  className?: string;
  small?: boolean;
}

function Muted({
  className,
  children,
  small = false,
}: PropsWithChildren<MutedProps>): ReactElement {
  return (
    <p className={`${css.muted} ${className} ${small != null ? css.small : ''}`}>{children}</p>
  );
}

export default Muted;
