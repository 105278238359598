import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z, ZodType } from 'zod';
import { PostcoderSearchResult } from '../../../types/common.types';
import { Countries, CountryCodes } from '../../../types/countries.types';
import Flex, { Align, Gap } from '../../atoms/Flex/Flex';
import Muted from '../../atoms/Muted/Muted';
import TextInput, { TextInputProps } from '../../atoms/TextInput/TextInput';
import css from './AddressForm.module.scss';

interface AddressFormProps {
  onChange: (newState: PostcoderSearchResult) => void;
  onValidationError?: (error: string | undefined) => void;
  initialAddress?: PostcoderSearchResult;
  validationSchema?: Partial<{
    organisation: ZodType;
    number: ZodType;
    street: ZodType;
    dependentlocality: ZodType;
    posttown: ZodType;
    county: ZodType;
    postcode: ZodType;
    country: ZodType;
  }>;
  overrides?: Partial<{
    organisation: Omit<TextInputProps, 'value' | 'onChange'>;
    number: Omit<TextInputProps, 'value' | 'onChange'>;
    street: Omit<TextInputProps, 'value' | 'onChange'>;
    dependentlocality: Omit<TextInputProps, 'value' | 'onChange'>;
    posttown: Omit<TextInputProps, 'value' | 'onChange'>;
    county: Omit<TextInputProps, 'value' | 'onChange'>;
    postcode: Omit<TextInputProps, 'value' | 'onChange'>;
    country: Omit<TextInputProps, 'value' | 'onChange'>;
  }>;
  label?: string;
  fixedCountry?: CountryCodes;
}

function AddressForm({
  onChange,
  onValidationError = () => {},
  initialAddress,
  fixedCountry,
  label,
  validationSchema,
  overrides,
}: AddressFormProps): ReactElement {
  const [address, setAddress] = useState<PostcoderSearchResult>({
    organisation: '',
    summaryline: '',
    number: '',
    street: '',
    dependentlocality: '',
    posttown: '',
    county: '',
    postcode: '',
    country: fixedCountry ? Countries[fixedCountry].name : '',
  });
  const { t } = useTranslation();

  function buildSummaryLine(address: PostcoderSearchResult): string {
    return `${address.organisation ? `${address.organisation}, ` : ''}${address.number} ${address.street}, ${address.postcode} ${address.posttown}`.trim();
  }
  function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;
    const newAddress = { ...address, [name]: value };

    if (!Object.keys(address).includes(name)) return;

    setAddress({
      ...newAddress,
      summaryline: buildSummaryLine(newAddress),
    });
    onChange({
      ...newAddress,
      summaryline: buildSummaryLine(newAddress),
    });

    if (validationSchema) {
      const { error } = z.object(validationSchema).passthrough().safeParse(newAddress);
      onValidationError(error?.issues[0]?.message);
    }
  }
  function ignoreCommas(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === ',') {
      e.preventDefault();
    }
  }

  return (
    <div className={css.manualAddressInput}>
      <strong>
        <Muted>{label ?? t('common:address')}</Muted>
      </strong>
      <TextInput
        onKeyDown={ignoreCommas}
        onChange={handleChange}
        value={address.street}
        name="street"
        required={
          Object.keys(validationSchema ?? {}).includes('street') &&
          !validationSchema?.street?.isOptional()
        }
        label={t('common:street')}
        {...overrides?.street}
      />
      <Flex align={Align.START} gap={Gap.SM}>
        <TextInput
          onChange={handleChange}
          onKeyDown={ignoreCommas}
          required={
            Object.keys(validationSchema ?? {}).includes('number') &&
            !validationSchema?.number?.isOptional()
          }
          value={address.number}
          name="number"
          label={t('common:number')}
          {...overrides?.number}
        />
        <TextInput
          onChange={handleChange}
          onKeyDown={ignoreCommas}
          value={address.postcode}
          required={
            Object.keys(validationSchema ?? {}).includes('postcode') &&
            !validationSchema?.postcode?.isOptional()
          }
          name="postcode"
          label={t('common:postcode')}
          {...overrides?.postcode}
        />
      </Flex>
      <Flex align={Align.START} gap={Gap.SM}>
        <TextInput
          onChange={handleChange}
          onKeyDown={ignoreCommas}
          value={address.posttown}
          required={
            Object.keys(validationSchema ?? {}).includes('posttown') &&
            !validationSchema?.posttown?.isOptional()
          }
          name="posttown"
          label={t('common:city')}
          {...overrides?.posttown}
        />
        <TextInput
          onChange={handleChange}
          onKeyDown={ignoreCommas}
          required={
            Object.keys(validationSchema ?? {}).includes('county') &&
            !validationSchema?.county?.isOptional()
          }
          value={address.county}
          name="county"
          label={t('common:county')}
          {...overrides?.county}
        />
      </Flex>
      <Flex align={Align.START} gap={Gap.SM}>
        <TextInput
          onChange={handleChange}
          onKeyDown={ignoreCommas}
          required={
            !fixedCountry &&
            Object.keys(validationSchema ?? {}).includes('country') &&
            !validationSchema?.country?.isOptional()
          }
          name="country"
          disabled={Boolean(fixedCountry)}
          hidden={Boolean(fixedCountry)}
          tabIndex={fixedCountry ? -1 : undefined}
          value={fixedCountry ? Countries[fixedCountry].name : initialAddress?.country ?? ''}
          label={t('common:country')}
          {...overrides?.country}
        />
      </Flex>
      <TextInput
        onChange={handleChange}
        onKeyDown={ignoreCommas}
        value={address.organisation ?? ''}
        required={
          Object.keys(validationSchema ?? {}).includes('organisation') &&
          !validationSchema?.organisation?.isOptional()
        }
        name="organisation"
        label={t('common:buildingName')}
        {...overrides?.organisation}
      />
    </div>
  );
}

export default AddressForm;
