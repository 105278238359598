import { type PropsWithChildren, type ReactElement } from 'react';
import css from './Small.module.scss';

interface SmallProps {
  className?: string;
}

function Small({ className, children }: PropsWithChildren<SmallProps>): ReactElement {
  return <span className={`${css.small} ${className}`}>{children}</span>;
}

export default Small;
