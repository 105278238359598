import Notice, { NoticeType } from '../Notice/Notice.tsx';
import type { ReactElement } from 'react';
import css from './ErrorNotice.module.scss';

interface ErrorProps {
  errors: string | string[];
}
function ErrorNotice({ errors }: ErrorProps): ReactElement {
  return (
    <Notice type={NoticeType.ERROR} className={css.errorNotice}>
      {Array.isArray(errors) ? (
        <>
          {errors.map((error, i) => {
            return <span key={i}>{error}</span>;
          })}
        </>
      ) : (
        <>{errors}</>
      )}
    </Notice>
  );
}

export { ErrorNotice };
