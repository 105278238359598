import WizardWithSteps from '../../../../molecules/WizardWithSteps/WizardWithSteps.tsx';
import Login from '../wizardsteps/Login.tsx';
import UserSelection from '../wizardsteps/UserSelection.tsx';
import GiveAuthorisation from '../wizardsteps/GiveAuthorisation.tsx';
import { ReactElement } from 'react';
import Card from '../../../../molecules/Card/Card.tsx';
import css from './FingridInstructions.module.scss';

interface FingridInstructionsProps {
  onNext: () => void;
}

function FingridInstructions({ onNext }: FingridInstructionsProps): ReactElement {
  const steps = [
    {
      value: 'login',
      children: <Login />,
    },
    {
      value: 'user-selection',
      children: <UserSelection />,
    },
    {
      value: 'give-authorisation',
      children: <GiveAuthorisation />,
      btnNextHandler: onNext,
      btnNextDisabled: false,
    },
  ] as any;

  return (
    <Card className={css.fingridInstructions} unbound>
      <WizardWithSteps<typeof steps> heading="Fingrid Instructions" steps={steps} />
    </Card>
  );
}

export default FingridInstructions;
