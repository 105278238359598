import { observer } from 'mobx-react-lite';
import { useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../store/AppContext.ts';
import {
  FranceConsentSpecificConfiguration,
  HostedConsentModel,
} from '../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';
import { Completed } from '../../molecules/ConsentCompleted/Completed.tsx';
import { type ConsentStep } from '../../molecules/ConsentSteps/ConsentSteps.tsx';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import PRMCollection from './flow-specific/shared/PRMCollection/PRMCollection.tsx';
import ProvideConsent from './flow-specific/shared/ProvideConsent/ProvideConsent.tsx';
import { FRCommercialContextualData } from '../../../types/country-specific/france.types.ts';

enum ConsentFlowScreens {
  START = 'start',
  PRM_COLLECTION = 'prm-collection',
  PROVIDE_CONSENT = 'provide-consent',
  COMPLETED = 'completed',
}

export interface ComponentProps {
  consent: HostedConsentModel<FRCommercialContextualData, FranceConsentSpecificConfiguration>;
}

export const FlowSteps: ConsentStep[] = [
  { text: 'Get PRM code', value: 'prm' },
  { text: 'Provide consent', value: 'consent' },
];

function FrCommercialSpecific({ consent }: ComponentProps): ReactElement {
  const { t } = useTranslation('fr-flow');
  // This has to be done like this because we are using the same component
  // on the operator selection and the EANCollection screen
  FlowSteps[0].text = t('common.step1');
  FlowSteps[1].text = t('common.step2');
  const [screen, setScreen] = useState<ConsentFlowScreens>(ConsentFlowScreens.START);
  const { LanguageStore } = useContext(AppContext);
  LanguageStore.setDefaultLanguage(Languages.FR);
  LanguageStore.setAvailableLanguages([Languages.FR, Languages.EN]);

  function renderScreen(): ReactElement {
    if (consent != null) {
      switch (screen) {
        case ConsentFlowScreens.START:
          return (
            <StartScreen
              logo={consent.account.logo}
              logoMetadata={consent.account.logo_metadata ? consent.account.logo_metadata : null}
              partner={consent.account.name}
              onNext={() => {
                setScreen(ConsentFlowScreens.PRM_COLLECTION);
              }}
            />
          );
        case ConsentFlowScreens.PRM_COLLECTION:
          return (
            <PRMCollection
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.PROVIDE_CONSENT);
              }}
            />
          );
        case ConsentFlowScreens.PROVIDE_CONSENT:
          return (
            <ProvideConsent
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.COMPLETED);
              }}
            />
          );
        case ConsentFlowScreens.COMPLETED:
          return <Completed consent={consent} />;
      }
    }
    return <></>;
  }
  return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(FrCommercialSpecific);
