import React, { ReactElement, useContext, useState } from 'react';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../atoms/Flex/Flex.tsx';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';
import { Trans, useTranslation } from 'react-i18next';
import useDebouncedEffect from '../../../../../utils/useDebouncedEffect.ts';
import css from './Verification.module.scss';
import Card from '../../../../molecules/Card/Card.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import API, { APIResponse } from '../../../../../api/API.ts';
import { ConsentMethod, HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { ErrorNotice } from '../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import { MeterNumberBox } from '../../../../atoms/MeterNumberBox/MeterNumberBox.tsx';
import { FingridAgreement } from '../../../../../types/country-specific/finland.types.ts';
import FadeIn from '../../../../animations/FadeIn/FadeIn.tsx';
import Loading from '../../../../atoms/Loading/Loading.tsx';
import AppContext from '../../../../../store/AppContext.ts';
import { AxiosResponse } from 'axios';

interface VerificationProps {
  consent: HostedConsentModel;
  onNext: () => void;
  onBack: () => void;
}

function Verification({ onNext, onBack, consent }: VerificationProps): ReactElement {
  const { ConsentStore } = useContext(AppContext);

  const { t } = useTranslation('fi-flow');
  const [customerIdentification, setCustomerIdentification] = useState('');
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [customerIdentificationChecked, setCustomerIdentificationChecked] = useState(false);
  const [agreements, setAgreements] = useState<FingridAgreement[]>([]);

  function handleUpdateCustomerIdentification(e: React.ChangeEvent<HTMLInputElement>): void {
    setCustomerIdentification(e.target.value);
  }

  function getLatestExpiry(): Date {
    let expiry = new Date(consent.expiry);
    agreements.forEach(agreement => {
      const agreementExpiry = new Date(agreement.AgreementEndDate);
      if (agreementExpiry.getTime() > expiry.getTime()) {
        expiry = agreementExpiry;
      }
    });
    return expiry;
  }

  async function handleSaveAuthorisationData(): Promise<void> {
    if (agreements.length > 0) {
      setLoading(true);
      const expiry = getLatestExpiry();
      await API.updateConsent(consent.id, {
        expiry: expiry.toUTCString(),
        meter_numbers: agreements.map(a => a.MeteringPoint.MeteringPointEAN),
        consent_method: ConsentMethod.DIGITAL_CONSENT,
      })
        .then(res => {
          ConsentStore.setConsent(res.data.data);
        })
        .catch(error => {
          setError(error.response.data.errors[0].message);
          setLoading(false);
        });
      await API.approveConsent(consent.id);
      setLoading(false);
      onNext();
    }
  }

  async function checkAuthorisation(): Promise<void> {
    if (consent && customerIdentification.length > 0) {
      setLoading(true);
      setError(null);
      setCustomerIdentificationChecked(false);
      await API.axios
        .get<APIResponse<{ agreements: FingridAgreement[] }>>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/fi/authorization-check`,
          {
            params: {
              customerIdentification: customerIdentification.trim(),
            },
          },
        )
        .then(({ data }: AxiosResponse<APIResponse<{ agreements: FingridAgreement[] }>>) => {
          if (data.data) {
            setCustomerIdentificationChecked(true);
            setAgreements(data.data.agreements);
            if (data.data.agreements.length > 0) {
              setValidated(true);
            }
          }
        })
        .catch(error => {
          setError(error.response.data.errors[0].message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useDebouncedEffect(
    async () => {
      await checkAuthorisation();
    },
    [customerIdentification],
    800,
  );

  return (
    <Card className={css.verification}>
      <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} fullWidth gap={Gap.MD}>
        <Heading>Verify connection</Heading>
        <Trans i18nKey="fi-flow:verification.introduction" />
        <TextInput
          disabled={loading}
          value={customerIdentification}
          onChange={handleUpdateCustomerIdentification}
          label={t('verification.national-identification-label')}
        />
        <>
          {loading && <Loading />}
          {error && <ErrorNotice errors={error} />}
          {customerIdentificationChecked && (
            <>
              {agreements.length === 0 && <ErrorNotice errors={t('verification.no-agreements')} />}
              {agreements.length > 0 && (
                <FadeIn className={css.agreementsWrapper}>
                  <Heading>
                    <Trans i18nKey="fi-flow:verification.agreements-title" />
                  </Heading>
                  <Flex
                    className={css.agreements}
                    flexDirection={FlexDirection.COLUMN}
                    gap={Gap.MD}
                    align={Align.STRETCH}>
                    {agreements.map((agreement, index) => {
                      const meteringPoint = agreement.MeteringPoint;
                      const address = meteringPoint.MeteringPointAddress;
                      return (
                        <MeterNumberBox
                          key={index}
                          meterNumber={agreement.MeteringPoint.MeteringPointEAN}
                          addressString={`${address.StreetName} ${address.PostalCode}`}
                          fullWidth
                        />
                      );
                    })}
                  </Flex>
                </FadeIn>
              )}
            </>
          )}
        </>
        <Flex justify={Justify.SPACE_BETWEEN}>
          <Button outline onClick={onBack}>
            {t('common:back')}
          </Button>
          <Button disabled={loading || !validated} onClick={handleSaveAuthorisationData}>
            {t('verification.complete')}
          </Button>
        </Flex>
      </Flex>
    </Card>
  );
}

export default Verification;
