import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import { ReactElement, useContext, useState } from 'react';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import { Completed } from '../../molecules/ConsentCompleted/Completed.tsx';
import AppContext from '../../../store/AppContext.ts';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';
import { observer } from 'mobx-react-lite';
import FingridInstructions from './flow-specific/FingridInstructions/FingridInstructions.tsx';
import Verification from './flow-specific/Verification/Verification.tsx';

interface FiSpecificProps {
  consent: HostedConsentModel;
}

enum ConsentFlowScreens {
  START = 'start',
  FINGRID_INSTRUCTIONS = 'fingrid-instructions',
  VERIFICATION = 'verification',
  COMPLETED = 'completed',
}

function FiSpecific({ consent }: FiSpecificProps): ReactElement {
  const [screen, setScreen] = useState<ConsentFlowScreens>(ConsentFlowScreens.START);

  const { LanguageStore } = useContext(AppContext);
  LanguageStore.setDefaultLanguage(Languages.FI);
  LanguageStore.setAvailableLanguages([Languages.FI, Languages.EN]);
  function renderScreen(): ReactElement {
    if (consent != null) {
      switch (screen) {
        case ConsentFlowScreens.START:
          return (
            <StartScreen
              logo={consent.account.logo}
              logoMetadata={consent.account.logo_metadata ? consent.account.logo_metadata : null}
              partner={consent.account.name}
              onNext={() => {
                setScreen(ConsentFlowScreens.FINGRID_INSTRUCTIONS);
              }}
            />
          );
        case ConsentFlowScreens.FINGRID_INSTRUCTIONS:
          return (
            <FingridInstructions
              onNext={() => {
                setScreen(ConsentFlowScreens.VERIFICATION);
              }}
            />
          );
        case ConsentFlowScreens.VERIFICATION:
          return (
            <Verification
              onNext={() => {
                setScreen(ConsentFlowScreens.COMPLETED);
              }}
              onBack={() => {
                setScreen(ConsentFlowScreens.FINGRID_INSTRUCTIONS);
              }}
              consent={consent}
            />
          );
        case ConsentFlowScreens.COMPLETED:
          return <Completed consent={consent} />;
      }
    }
    return <></>;
  }

  return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(FiSpecific);
