import { ReactElement, useContext, useState } from 'react';
import { ConsentMethod, HostedConsentModel } from '../../../types/hosted-consent.types';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../atoms/Flex/Flex';
import Heading from '../../atoms/Heading/Heading';
import { MeterNumberBox } from '../../atoms/MeterNumberBox/MeterNumberBox';
import { NeedHelpText } from '../../atoms/NeedHelpText/NeedHelpText';
import PrivacyText from '../../atoms/PrivacyText/PrivacyText';
import css from './OperatorSpecificScreenLayout.module.scss';
import Small from '../../atoms/Small/Small';
import ConsentExpiry from '../ConsentExpiry/ConsentExpiry';
import Button from '../../atoms/Button/Button';
import { Spinner } from '../../atoms/Spinner/Spinner';
import { CheckIcon } from '@heroicons/react/24/outline';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import navigateToCallback from '../../../utils/navigateToCallback';
import AppContext from '../../../store/AppContext';
import API from '../../../api/API';
import { useTranslation } from 'react-i18next';

interface Operator {
  name: string;
  image: string;
}

interface OperatorSpecificFlowProps {
  onBackToOperatorSelector: () => void;
  onValidateConnection?: () => Promise<boolean>;
  onProceed: () => Promise<void>;
  validateButtonDisabled?: () => boolean;
  consent: HostedConsentModel;
  children?: ReactElement;
  operator: Operator;
  needHelpLink?: string;
}

export function OperatorSpecificScreenLayout(props: OperatorSpecificFlowProps): ReactElement {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [validationSuccess, setValidationSuccess] = useState(false);
  const [validationPerformed, setValidationPerformed] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>(t('validation:general.unknownError'));
  const runDisabledValidation = props.validateButtonDisabled ?? (() => false);
  const hasValidation = !!props.onValidateConnection;
  const { ConsentStore } = useContext(AppContext);

  async function handleValidateConnection(retry: boolean = false): Promise<void> {
    try {
      if (!props.onValidateConnection) return;
      if (validationPerformed && !retry) return;
      if (retry) {
        setValidationPerformed(false);
        setValidationSuccess(false);
      }

      setLoading(true);
      const success = await props.onValidateConnection();

      if (success) {
        setValidationSuccess(true);
        return;
      }
      setValidationSuccess(false);
      setErrorMessage(t('validation:operatorSpecificLayout.verifyDetails'));
    } catch (err) {
      setValidationSuccess(false);
      setErrorMessage(t('validation:operatorSpecificLayout.validationError'));
    } finally {
      setValidationPerformed(true);
      setLoading(false);
    }
  }

  async function approveConsent(): Promise<void> {
    const res = await API.updateConsent(props.consent.id, {
      consent_method: ConsentMethod.DIGITAL_CONSENT,
    });
    ConsentStore.setConsent(res.data.data);
    await API.approveConsent(props.consent.id);
  }

  async function handleProceed(): Promise<void> {
    try {
      setLoading(true);
      await props.onProceed();
      await approveConsent();
    } catch (error) {
      setValidationSuccess(false);
      setErrorMessage(t('validation:operatorSpecificLayout.errorSavingData'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <Flex
      className={css.operatorSpecificScreenLayout}
      align={Align.STRETCH}
      flexDirection={FlexDirection.COLUMN}>
      <Flex className={css.operatorHeader} align={Align.STRETCH} justify={Justify.SPACE_BETWEEN}>
        <img src={props.operator.image} alt={props.operator.name} className={css.operatorImage} />
        <Small className={css.backButtonWrapper}>
          <button type="button" className={css.backButton} onClick={props.onBackToOperatorSelector}>
            {t('common:change')}
          </button>
        </Small>
      </Flex>
      <h4>{t('common:operatorSpecificLayout.consent')}</h4>
      <div style={{ marginBottom: '10px' }}>
        {props.consent.meter_numbers?.map(meterNumber => (
          <MeterNumberBox key={meterNumber} meterNumber={meterNumber} />
        ))}
      </div>

      <Flex
        flexDirection={FlexDirection.COLUMN}
        align={Align.STRETCH}
        justify={Justify.START}
        gap={Gap.SM}
        className={css.additionalData}>
        <Heading>{t('common:operatorSpecificLayout.additionalData')}</Heading>
        {props.children}

        <ConsentExpiry consent={props.consent} />
        <PrivacyText />
        {hasValidation ? (
          <Button
            className={`${css.primaryButton} ${
              validationPerformed && validationSuccess && !loading
                ? css.successButton
                : validationPerformed && !validationSuccess && !loading
                  ? css.errorButton
                  : ''
            }`}
            fullWidth={true}
            onClick={handleValidateConnection}
            disabled={
              loading || runDisabledValidation() || (validationPerformed && validationSuccess)
            }>
            {loading ? (
              <Flex gap={Gap.MD}>
                <Spinner /> <span>{t('common:verifying')}</span>
              </Flex>
            ) : runDisabledValidation() ? (
              t('validation:general.missingFields')
            ) : validationPerformed && validationSuccess ? (
              <Flex gap={Gap.MD}>
                <CheckIcon className={css.icon} />{' '}
                <span>{t('common:operatorSpecificLayout.connectionValidated')}</span>
              </Flex>
            ) : validationPerformed && !validationSuccess ? (
              <Flex gap={Gap.MD}>
                <ExclamationTriangleIcon className={css.icon} /> <span>{errorMessage}</span>
              </Flex>
            ) : (
              t('common:operatorSpecificLayout.validateConnection')
            )}
          </Button>
        ) : null}
        {hasValidation && validationPerformed && validationSuccess ? (
          <Button fullWidth={true} onClick={handleProceed}>
            {t('common:proceed')}
          </Button>
        ) : hasValidation && validationPerformed && !validationSuccess ? (
          <Flex gap={Gap.SM}>
            <Button
              onClick={() => {
                navigateToCallback(props.consent);
              }}
              className={css.blankButton}>
              {t('common:backToAccount', { account: props.consent.account.name })}
            </Button>
            <Button
              onClick={async () => {
                await handleValidateConnection(true);
              }}
              className={css.primaryButton}>
              {t('common:tryAgain')}
            </Button>
          </Flex>
        ) : !hasValidation ? (
          <Button
            disabled={loading || runDisabledValidation()}
            fullWidth={true}
            onClick={handleProceed}>
            {t('common:proceed')}
          </Button>
        ) : null}
      </Flex>
      {props.needHelpLink && <NeedHelpText linkHref={props.needHelpLink} />}
    </Flex>
  );
}
