import { ReactElement } from 'react';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import css from './common.module.scss';
import image from './images/2-user-selection.png';
import { Trans } from 'react-i18next';

function UserSelection(): ReactElement {
  return (
    <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.LG}>
      <img className={css.image} src={image as never} />
      <div>
        <Trans
          className={css.whitespace}
          i18nKey="fi-flow:fingridInstructions.userSelection.description"
        />
      </div>
    </Flex>
  );
}

export default UserSelection;
