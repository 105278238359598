import { ReactElement } from 'react';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import navigateToCallback from '../../../utils/navigateToCallback.ts';
import Button from '../../atoms/Button/Button.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Muted from '../../atoms/Muted/Muted.tsx';
import Card from '../Card/Card.tsx';
import css from './Completed.module.scss';
import { useTranslation } from 'react-i18next';

interface CompletedProps {
  consent: HostedConsentModel<any, any>;
  hasExtraSteps?: boolean;
}

function Completed({ consent, hasExtraSteps = false }: CompletedProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Card className={css.addressVerification} account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <div>
          {hasExtraSteps ? (
            <>
              <Heading>{t('common:completedConsentScreen.headingExtraSteps')}</Heading>
              <Muted>{t('common:completedConsentScreen.descriptionExtraSteps')}</Muted>
            </>
          ) : (
            <>
              <Heading>{t('common:completedConsentScreen.heading')}</Heading>
              <Muted>
                {t('common:completedConsentScreen.description', {
                  account: consent.account.name,
                })}
              </Muted>
            </>
          )}
        </div>
        <Muted>{t('common:completedConsentScreen.cta', { account: consent.account.name })}</Muted>
        <Button
          fullWidth
          onClick={() => {
            navigateToCallback(consent);
          }}>
          {t('common:returnToApp', { account: consent.account.name })}
        </Button>
      </Flex>
    </Card>
  );
}

export { Completed };
