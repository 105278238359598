import { type ReactElement, useEffect, useState, useContext } from 'react';
import { useSearch } from 'wouter';
import { observer } from 'mobx-react-lite';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import MPANCollection from './flow-specific/MPANCollection/MPANCollection.tsx';
import { type ConsentStep } from '../../molecules/ConsentSteps/ConsentSteps.tsx';
import { AddressVerification } from './flow-specific/AddressVerification/AddressVerification.tsx';
import {
  ConsentEvidenceStatus,
  ConsentEvidenceType,
  HostedConsentModel,
} from '../../../types/hosted-consent.types.ts';
import ProvideConsent from './flow-specific/ProvideConsent/ProvideConsent.tsx';
import { Completed } from '../../molecules/ConsentCompleted/Completed.tsx';
import AppContext from '../../../store/AppContext.ts';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';

enum ConsentFlowScreens {
  START = 'start',
  MPAN_COLLECTION = 'mpan-collection',
  ADDRESS_VERIFICATION = 'address-verification',
  PROVIDE_CONSENT = 'provide-consent',
  COMPLETED = 'completed',
}

export interface GBResidentialSpecificProps {
  consent: HostedConsentModel;
}

export const GBResidentialSpecificConsentSteps: ConsentStep[] = [
  { text: 'Get MPAN', value: 'mpan' },
  { text: 'Verify Address', value: 'address' },
  { text: 'Provide Consent', value: 'consent' },
];

function GbResidentialSpecific({ consent }: GBResidentialSpecificProps): ReactElement {
  const [screen, setScreen] = useState<ConsentFlowScreens>(ConsentFlowScreens.START);
  const { LanguageStore } = useContext(AppContext);

  const search = useSearch();
  const searchParams = new URLSearchParams(search);
  const setupIntentClientSecret = searchParams.get('setup_intent_client_secret');

  useEffect(() => {
    LanguageStore.setAvailableLanguages([Languages.EN]);
    // If the consent has an energy bill evidence document, go to the address verification screen
    // In that screen we filter the step it should be on
    if (
      consent.evidence_document_metadata?.evidence_type === ConsentEvidenceType.ENERGY_BILL &&
      consent.evidence_document_metadata?.review_status === ConsentEvidenceStatus.REJECTED
    ) {
      setScreen(ConsentFlowScreens.ADDRESS_VERIFICATION);
    }
  }, []);

  useEffect(() => {
    if (setupIntentClientSecret) {
      setScreen(ConsentFlowScreens.ADDRESS_VERIFICATION);
    }
  }, [setupIntentClientSecret]);

  function renderScreen(): ReactElement {
    if (consent != null) {
      switch (screen) {
        case ConsentFlowScreens.START:
          return (
            <StartScreen
              logo={consent.account.logo}
              logoMetadata={consent.account.logo_metadata ? consent.account.logo_metadata : null}
              partner={consent.account.name}
              onNext={() => {
                setScreen(ConsentFlowScreens.MPAN_COLLECTION);
              }}
            />
          );
        case ConsentFlowScreens.MPAN_COLLECTION:
          return (
            <MPANCollection
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.ADDRESS_VERIFICATION);
              }}
            />
          );
        case ConsentFlowScreens.ADDRESS_VERIFICATION:
          return (
            <AddressVerification
              setupIntentClientSecret={setupIntentClientSecret}
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.PROVIDE_CONSENT);
              }}
              onBack={() => {
                setScreen(ConsentFlowScreens.MPAN_COLLECTION);
              }}
            />
          );
        case ConsentFlowScreens.PROVIDE_CONSENT:
          return (
            <ProvideConsent
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.COMPLETED);
              }}
            />
          );
        case ConsentFlowScreens.COMPLETED:
          return <Completed consent={consent} />;
      }
    }
    return <></>;
  }

  return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(GbResidentialSpecific);
