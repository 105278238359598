import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';
import { ReactElement, useRef, useState } from 'react';
import Flex, { Align, FlexDirection, Gap, Justify } from '../Flex/Flex';
import css from './CollapsibleNotice.module.scss';

export interface ComponentProps {
  title: string;
  children: ReactElement | ReactElement[];
  className?: string;
  iconOverride?: ReactElement;
  type?: 'info' | 'success' | 'error' | 'display';
  startOpen?: boolean;
}

export default function CollapsibleNotice({
  children,
  title,
  className,
  type,
  iconOverride,
  startOpen = false,
}: ComponentProps): ReactElement {
  const [isOpen, setIsOpen] = useState(startOpen);
  const contentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={`${css.collapsibleNotice} ${className} ${type && css[`type_${type}`]} ${
        isOpen ? 'open' : ''
      }`}>
      <Flex
        flexDirection={FlexDirection.ROW}
        gap={Gap.SM}
        align={Align.CENTER}
        justify={Justify.START}
        fullWidth={true}
        className={`${css.infoFlex} ${isOpen ? css.open : ''}`}>
        {type === 'info' && <InformationCircleIcon className={css.icon} />}
        {type === 'success' && <CheckCircleIcon className={css.icon} />}
        {type === 'error' && <ExclamationTriangleIcon className={css.icon} />}
        {type === 'display' && <InformationCircleIcon className={css.icon} />}
        {type === undefined && (
          <>
            {iconOverride ? (
              <div data-name="iconOverride">{iconOverride}</div>
            ) : (
              <InformationCircleIcon className={css.icon} />
            )}
          </>
        )}
        <h4>{title}</h4>
        <Flex className={css.chevronWrapper} justify={Justify.END}>
          {!isOpen && (
            <ChevronDownIcon
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={`${css.icon} ${css.chevron}`}
            />
          )}
          {isOpen && (
            <ChevronUpIcon
              onClick={() => {
                setIsOpen(!isOpen);
              }}
              className={`${css.icon} ${css.chevron}`}
            />
          )}
        </Flex>
      </Flex>

      <div
        className={`${css.contentParent} ${isOpen ? css.open : ''}`}
        ref={contentRef}
        style={{ height: isOpen ? contentRef.current?.scrollHeight + 'px' : 0 }}>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  );
}
