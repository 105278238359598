import { type ReactElement } from 'react';
import Flex, { FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import css from './NotFound.module.scss';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';

function NotFound(): ReactElement {
  return (
    <Flex className={css.notFound} flexDirection={FlexDirection.COLUMN}>
      <FadeIn>
        <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.XXL}>
          <div className={css.message}>
            <h2>Consent not found</h2>
            <p>
              This consent could not be found. This could be because the consent was deleted or some
              other issue. Please contact your software provider to report this issue.
            </p>
          </div>
          <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.SM}>
            <img className={css.logo} src="/logo-full.svg" />
            <span>v{APP_VERSION}</span>
          </Flex>
        </Flex>
      </FadeIn>
    </Flex>
  );
}

export default NotFound;
