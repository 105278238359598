import { ChangeEvent, ReactElement, useContext, useState } from 'react';
import API from '../../../../../../api/API';
import AppContext from '../../../../../../store/AppContext';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice';
import Flex, { FlexDirection, Gap } from '../../../../../atoms/Flex/Flex';
import TextInput from '../../../../../atoms/TextInput/TextInput';
import { OperatorSpecificScreenLayout } from '../../../../../organisms/OperatorSpecificScreenLayout/OperatorSpecificScreenLayout';
import AnexoLogo from '../../shared/OperatorSelection/images/anexo.png';
import { AvailableNLCommercialOperators, OperatorSpecificFlowProps } from '../../shared/types';
import css from './SpecificFlow.module.scss';
import { useTranslation } from 'react-i18next';

export function FuduraSpecificFlow({
  consent,
  onBackToOperatorSelector,
  onNext,
}: OperatorSpecificFlowProps): ReactElement {
  interface AuthorizationData {
    customer_id: string;
  }
  const { t } = useTranslation('nl-flow');
  const [authorizationData, setAuthorizationData] = useState<AuthorizationData>({
    customer_id: '',
  });
  const [error, setError] = useState<string | null>(null);

  const { ConsentStore } = useContext(AppContext);

  async function handleProceed(): Promise<void> {
    try {
      setError(null);
      if (!authorizationData) return;
      consent.authorization_data = authorizationData;
      ConsentStore.setConsent(consent);

      await API.updateAuthorizationData(consent.id, {
        authorization_data: authorizationData,
      });
      onNext();
    } catch (err) {
      setError(t('validation:general.authorizationDataFailedUpdate'));
    }
  }

  function handleInput(key: keyof AuthorizationData) {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      setAuthorizationData({ ...authorizationData, [key]: value });
    };
  }

  function validateButtonDisabled(): boolean {
    if (!authorizationData) return true;
    // if any of the fields are empty, the button should be disabled
    return (Object.keys(authorizationData) as Array<keyof AuthorizationData>).some(
      key => !authorizationData[key],
    );
  }

  return (
    <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD}>
      <OperatorSpecificScreenLayout
        consent={consent}
        onBackToOperatorSelector={onBackToOperatorSelector}
        onProceed={handleProceed}
        validateButtonDisabled={validateButtonDisabled}
        operator={{ image: AnexoLogo, name: AvailableNLCommercialOperators.ANEXO }}>
        <div className={css.flow}>
          <TextInput
            value={authorizationData.customer_id}
            label={t('nl-flow:fuduraFlow.customerId')}
            name="customer_id"
            type="text"
            autocomplete="off"
            className={css.specificInput}
            onChange={handleInput('customer_id')}
          />
        </div>
      </OperatorSpecificScreenLayout>
      {error && <ErrorNotice errors={error ?? ''} />}
    </Flex>
  );
}
