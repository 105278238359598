import { ReactElement, useEffect, useState } from 'react';
import {
  ConsentEvidenceStatus,
  HostedConsentModel,
} from '../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import Muted from '../../../../atoms/Muted/Muted.tsx';
import Notice, { NoticeType } from '../../../../atoms/Notice/Notice.tsx';
import Card from '../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { AddressPaymentVerification } from '../../../../organisms/AddressPaymentVerification/AddressPaymentVerification.tsx';
import { GBResidentialSpecificConsentSteps } from '../../gb.residential.specific.tsx';
import {
  AddressVerificationMethod,
  VerificationMethod,
} from '../AddressVerificationMethod/AddressVerificationMethod.tsx';

import { Trans, useTranslation } from 'react-i18next';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import AddressBillVerification from '../../../../organisms/AddressBillVerification/AddressBillVerification.tsx';
import css from './AddressVerification.module.scss';

interface AddressVerificationProps {
  setupIntentClientSecret: string | null;
  consent: HostedConsentModel;
  onNext: () => void;
  onBack: () => void;
}

enum AddressVerificationSteps {
  VERIFICATION_METHOD = 'verification-method',
  PAYMENT_VERIFICATION = 'payment-verification',
  ELECTRICITY_BILL = 'electricity-bill',
  ADDRESS_VERIFIED = 'address-verified',
  ADDRESS_VERIFIED_HOLD = 'address-verified-hold',
  ADDRESS_NOT_VERIFIED = 'address-not-verified',
}
function AddressVerification({
  consent,
  onNext,
  onBack,
  setupIntentClientSecret,
}: AddressVerificationProps): ReactElement {
  const { t } = useTranslation('uk-flow');
  const [step, setStep] = useState(AddressVerificationSteps.VERIFICATION_METHOD);

  // this is for when the user comes back to the flow after sending the bill
  useEffect(() => {
    if (consent.evidence_document !== null) {
      switch (consent.evidence_document_metadata?.review_status) {
        case ConsentEvidenceStatus.PENDING:
          setStep(AddressVerificationSteps.ADDRESS_VERIFIED_HOLD);
          break;
        case ConsentEvidenceStatus.REJECTED:
          setStep(AddressVerificationSteps.ADDRESS_NOT_VERIFIED);
          break;
      }
    }
  }, [consent.evidence_document, consent.evidence_document_metadata?.review_status]);

  useEffect(() => {
    if (setupIntentClientSecret) {
      setStep(AddressVerificationSteps.PAYMENT_VERIFICATION);
    }
  }, [setupIntentClientSecret]);

  function changeVerificationMethod(): void {
    setStep(AddressVerificationSteps.VERIFICATION_METHOD);
  }

  function renderStep(): ReactElement {
    switch (step) {
      case AddressVerificationSteps.VERIFICATION_METHOD:
        return (
          <AddressVerificationMethod
            consent={consent}
            onSelectVerificationMethod={(method: VerificationMethod) => {
              switch (method) {
                case VerificationMethod.PAYMENT_METHOD:
                  setStep(AddressVerificationSteps.PAYMENT_VERIFICATION);
                  break;
                case VerificationMethod.ELECTRICITY_BILL:
                  setStep(AddressVerificationSteps.ELECTRICITY_BILL);
                  break;
              }
            }}
            onBack={() => {
              onBack();
            }}
          />
        );
      // stripe verification
      case AddressVerificationSteps.PAYMENT_VERIFICATION:
        return (
          <AddressPaymentVerification
            consent={consent}
            setupIntentClientSecret={setupIntentClientSecret}
            onVerified={() => {
              setStep(AddressVerificationSteps.ADDRESS_VERIFIED);
            }}
            onUnverified={() => {
              setStep(AddressVerificationSteps.ADDRESS_NOT_VERIFIED);
            }}
            onBack={changeVerificationMethod}
          />
        );
      case AddressVerificationSteps.ELECTRICITY_BILL:
        return (
          <AddressBillVerification
            onBack={changeVerificationMethod}
            consent={consent}
            onVerified={(status: number) => {
              switch (status) {
                case 200: {
                  setStep(AddressVerificationSteps.ADDRESS_VERIFIED);
                  return;
                }
                case 202: {
                  setStep(AddressVerificationSteps.ADDRESS_VERIFIED_HOLD);
                  return;
                }
                default: {
                  setStep(AddressVerificationSteps.ADDRESS_NOT_VERIFIED);
                }
              }
            }}
          />
        );
      // evidence approved
      case AddressVerificationSteps.ADDRESS_VERIFIED:
        return (
          <Flex fullWidth flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.LG}>
            <Notice type={NoticeType.SUCCESS}>
              {t('residentialSpecificFlow.addressVerification.success')}
            </Notice>
            <Button fullWidth onClick={onNext}>
              {t('common:next')}
            </Button>
          </Flex>
        );
      // When the user uploaded the evidence, but it hasn't still been approved
      case AddressVerificationSteps.ADDRESS_VERIFIED_HOLD:
        return (
          <Flex fullWidth flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.LG}>
            <Heading>{t('residentialSpecificFlow.addressVerification.hold.heading')}</Heading>
            <Muted>
              <Trans
                i18nKey={'residentialSpecificFlow.addressVerification.hold.subHeading'}
                values={{ account: consent.account.name }}>
                {t('residentialSpecificFlow.addressVerification.hold.subHeading', {
                  account: consent.account.name,
                })}
              </Trans>
            </Muted>
            <Button fullWidth onClick={onNext}>
              {t('common:next')}
            </Button>
          </Flex>
        );
      // Rejected evidence
      case AddressVerificationSteps.ADDRESS_NOT_VERIFIED:
        return (
          <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD}>
            <Heading>{t('residentialSpecificFlow.addressVerification.failedHeading')}</Heading>
            <Muted>{t('residentialSpecificFlow.addressVerification.failedSubHeading')}</Muted>
            <Flex gap={Gap.MD} fullWidth>
              <Button fullWidth onClick={changeVerificationMethod}>
                {t('common:retry')}
              </Button>
            </Flex>
          </Flex>
        );
    }
  }

  return (
    <Card className={css.addressVerification} account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <ConsentSteps
          steps={GBResidentialSpecificConsentSteps}
          current={GBResidentialSpecificConsentSteps[1]}
        />
        <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
      </Flex>
    </Card>
  );
}

export { AddressVerification };
