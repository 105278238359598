import { ReactElement } from 'react';
import { Trans } from 'react-i18next';
import image from './images/1-login.png';
import css from './common.module.scss';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';

function Login(): ReactElement {
  return (
    <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.LG}>
      <img className={css.image} src={image as never} />
      <div>
        <Trans
          className={css.whitespace}
          i18nKey="fi-flow:fingridInstructions.login.description"
          components={{
            i18anchor: (
              <a href="https://oma.datahub.fi" target="_blank" rel="noreferrer">
                https://oma.datahub.fi
              </a>
            ),
          }}
        />
      </div>
    </Flex>
  );
}

export default Login;
