import { AxiosError } from 'axios';
import { useContext, useState, type ReactElement } from 'react';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import {
  SelectableGrid,
  SelectableGridItem,
} from '../../../../../molecules/SelectableGrid/SelectableGrid.tsx';
import NotSupposedToBeHere from '../../../../../pages/NotSupposedToBeHere/NotSupposedToBeHere.tsx';
import { NLCommercialSpecificConsentSteps } from '../../../nl.commercial.specific.tsx';
import { AnexoSpecificFlow } from '../../operator-flows/anexo/SpecificFlow.tsx';
import { MeetbedrijfSpecificFlow } from '../../operator-flows/het-meetbedrijf/SpecificFlow.tsx';
import { JoulzSpecificFlow } from '../../operator-flows/joulz/SpecificFlow.tsx';
import { KenterSpecificFlow } from '../../operator-flows/kenter/SpecificFlow.tsx';
import { AvailableNLCommercialOperators } from '../types.ts';
import css from './OperatorSelection.module.scss';
import anexoLogo from './images/anexo.png';
import edsnLogo from './images/edsn.png';
import fuduraLogo from './images/fudura.png';
import innaxLogo from './images/innax.png';
import joulzLogo from './images/joulz.png';
import kenterLogo from './images/kenter.png';
import meetbedrijfLogo from './images/meetbedrijf.png';
import tumsLogo from './images/tums.png';
import { FuduraSpecificFlow } from '../../operator-flows/fudura/SpecificFlow.tsx';
import { useTranslation } from 'react-i18next';

enum OperatorSelectionSteps {
  OPERATOR_SELECTION = 'operator_selection',
  OPERATOR_SPECIFIC_FLOW = 'operator_specific_flow',
}

interface OperatorSelectionProps {
  consent: HostedConsentModel;
  onNext: (hasExtraSteps: boolean) => void;
}

function OperatorSelection({ consent, onNext }: OperatorSelectionProps): ReactElement {
  // #region State
  const { t } = useTranslation('nl-flow');
  const [errors, setErrors] = useState<null | string | string[]>(null);
  const operatorGridItems = [
    {
      id: AvailableNLCommercialOperators.ANEXO,
      image: anexoLogo,
      enabled: true,
      hasExtraSteps: false,
    },
    {
      id: AvailableNLCommercialOperators.EDSN,
      image: edsnLogo,
      enabled: false,
      hasExtraSteps: false,
    },
    {
      id: AvailableNLCommercialOperators.FUDURA,
      image: fuduraLogo,
      enabled: true,
      hasExtraSteps: true,
    },
    {
      id: AvailableNLCommercialOperators.MEETBEDRIJF,
      image: meetbedrijfLogo,
      enabled: true,
      hasExtraSteps: true,
    },
    {
      id: AvailableNLCommercialOperators.INNAX,
      image: innaxLogo,
      enabled: false,
      hasExtraSteps: false,
    },
    {
      id: AvailableNLCommercialOperators.JOULZ,
      image: joulzLogo,
      enabled: true,
      hasExtraSteps: false,
    },
    {
      id: AvailableNLCommercialOperators.KENTER,
      image: kenterLogo,
      enabled: true,
      hasExtraSteps: false,
    },
    {
      id: AvailableNLCommercialOperators.TUMS,
      image: tumsLogo,
      enabled: false,
      hasExtraSteps: false,
    },
  ]
    // Sort the operators by enabled status putting disabled in the end
    .sort((a, b) => (a.enabled === b.enabled ? 0 : a.enabled ? -1 : 1));

  const [step, setStep] = useState(OperatorSelectionSteps.OPERATOR_SELECTION);

  /**
   * Stuff the user provides or clicks on.
   */
  const [selectedOperator, setSelectedOperator] = useState<null | AvailableNLCommercialOperators>(
    null,
  );

  const { ConsentStore } = useContext(AppContext);
  // #endregion

  // #region Handlers
  async function onNextWrapper(): Promise<void> {
    onNext(operatorGridItems.find(i => i.id === selectedOperator)?.hasExtraSteps ?? false);
  }
  async function handleSelectOperator(operator: SelectableGridItem): Promise<void> {
    try {
      setSelectedOperator(operator.id as AvailableNLCommercialOperators);
      const { data: apiResponse } = await API.updateConsent(consent.id, {
        contextual_data: {
          ...consent.contextual_data,
          selected_operator: operator.id,
        },
      });
      ConsentStore.setConsent(apiResponse.data);
      setStep(OperatorSelectionSteps.OPERATOR_SPECIFIC_FLOW);
    } catch (err) {
      if (err instanceof AxiosError && err?.response?.data?.errors != null) {
        setErrors(err.response.data.errors);
        return;
      }
      setErrors(err instanceof Error ? err.message : t('validation:general.unknownError'));
    }
  }

  function handleBackToOperatorSelector(): void {
    setStep(OperatorSelectionSteps.OPERATOR_SELECTION);
  }

  // #endregion

  function renderStep(): ReactElement {
    switch (step) {
      case OperatorSelectionSteps.OPERATOR_SELECTION:
        return (
          <Flex
            className={css.operatorSelection}
            gap={Gap.LG}
            flexDirection={FlexDirection.COLUMN}
            fullWidth={true}
            justify={Justify.SPACE_BETWEEN}
            align={Align.STRETCH}>
            <Heading>{t('nl-flow:operatorSelectionStep.heading')}</Heading>
            {errors != null && <ErrorNotice errors={errors} />}
            <SelectableGrid onSelect={handleSelectOperator} items={operatorGridItems} />
            {/* <NeedHelpText linkHref="https://www.openvolt.com" />  FIX: to return when we have the correct link */}
          </Flex>
        );
      case OperatorSelectionSteps.OPERATOR_SPECIFIC_FLOW:
        switch (selectedOperator) {
          case AvailableNLCommercialOperators.ANEXO:
            return (
              <AnexoSpecificFlow
                onNext={onNextWrapper}
                consent={consent}
                onBackToOperatorSelector={handleBackToOperatorSelector}
              />
            );
          case AvailableNLCommercialOperators.EDSN:
            return <NotSupposedToBeHere />;
          case AvailableNLCommercialOperators.FUDURA:
            return (
              <FuduraSpecificFlow
                onNext={onNextWrapper}
                consent={consent}
                onBackToOperatorSelector={handleBackToOperatorSelector}
              />
            );
          case AvailableNLCommercialOperators.MEETBEDRIJF:
            return (
              <MeetbedrijfSpecificFlow
                onNext={onNextWrapper}
                consent={consent}
                onBackToOperatorSelector={handleBackToOperatorSelector}
              />
            );
          case AvailableNLCommercialOperators.INNAX:
            return <NotSupposedToBeHere />;
          case AvailableNLCommercialOperators.JOULZ:
            return (
              <JoulzSpecificFlow
                onNext={onNextWrapper}
                consent={consent}
                onBackToOperatorSelector={handleBackToOperatorSelector}
              />
            );
          case AvailableNLCommercialOperators.KENTER:
            return (
              <KenterSpecificFlow
                onNext={onNextWrapper}
                consent={consent}
                onBackToOperatorSelector={handleBackToOperatorSelector}
              />
            );
          case AvailableNLCommercialOperators.TUMS:
            return <NotSupposedToBeHere />;
          default:
            return <NotSupposedToBeHere />;
        }
      default:
        return <NotSupposedToBeHere />;
    }
  }

  return (
    <Card account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <ConsentSteps
          steps={NLCommercialSpecificConsentSteps}
          current={NLCommercialSpecificConsentSteps[1]}
        />
        <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
      </Flex>
    </Card>
  );
}

export default OperatorSelection;
