import { type ReactElement } from 'react';
import Card from '../Card/Card.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Button from '../../atoms/Button/Button.tsx';
import { AccountModel } from '../../../types/account.types.ts';
import { useTranslation } from 'react-i18next';

interface BlanketDescriptionScreenProps {
  partner: string;
  account: AccountModel;
  onNext: () => void;
}

function BlanketDescriptionScreen({
  partner,
  account,
  onNext,
}: BlanketDescriptionScreenProps): ReactElement {
  function handleScreenChange(): void {
    onNext();
  }
  const { t } = useTranslation();

  return (
    <Card account={account}>
      <Flex flexDirection={FlexDirection.COLUMN} align={Align.END} gap={Gap.XL}>
        <div>
          <Heading>{t('common:blanketDescriptionScreen.whatIs')}</Heading>
          <p>{t('common:blanketDescriptionScreen.description1', { partner })}</p>
          <p>{t('common:blanketDescriptionScreen.description2')}</p>
        </div>
        <Button onClick={handleScreenChange}>{t('common:understood')}</Button>
      </Flex>
    </Card>
  );
}

export default BlanketDescriptionScreen;
