import { type ReactElement } from 'react';
import css from './ApplicationError.module.scss';
import { useErrorBoundary } from 'react-error-boundary';
import Button from '../../atoms/Button/Button.tsx';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';

function ApplicationError(): ReactElement {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div className={css.applicationError}>
      <FadeIn>
        <Heading>Uh Oh... Something broke</Heading>
        <p>
          It looks like something caused this hosted consent flow to break. We have automatically
          logged this error and you don't need to report it. You can retry using the button below.
        </p>
        <Button onClick={resetBoundary}>Retry</Button>
      </FadeIn>
    </div>
  );
}

export default ApplicationError;
