import { ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../api/API.ts';
import AppContext from '../../../store/AppContext.ts';
import { ConsentEvidenceType, HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import Button from '../../atoms/Button/Button.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Muted from '../../atoms/Muted/Muted.tsx';
import Notice, { NoticeType } from '../../atoms/Notice/Notice.tsx';
import EvidenceUpload from '../EvidenceUpload/EvidenceUpload.tsx';
import { observer } from 'mobx-react-lite';
import { AxiosError } from 'axios';
import DocumentScanner from '../../animations/DocumentScanner/DocumentScanner.tsx';

interface AddressPaymentVerificationProps {
  consent: HostedConsentModel;
  onVerified: (status: number) => void;
  onUnverified?: () => void;
  onBack: () => void;
}

function AddressBillVerification({
  consent,
  onVerified,
  onBack,
}: AddressPaymentVerificationProps): ReactElement {
  const [error, setError] = useState(false);
  const { t } = useTranslation('common');
  const { ConsentStore } = useContext(AppContext);
  const [isVerifying, setIsVerifying] = useState(false);

  if (error) {
    return <Notice type={NoticeType.ERROR}>{t('addressBillVerificationScreen.error')}</Notice>;
  }

  const handleVerification = async (): Promise<void> => {
    setIsVerifying(true);
    try {
      const consentId = ConsentStore?.consent?.id;
      if (!consentId) return;
      const response = await API.ocrVerify(consentId);

      // NOTE: see https://github.com/openvolt/api/pull/325 for all the implementation details
      // 200 - success (verified)
      // 202 - could not automatically verify, sent to manual review
      // 403 - could not verify due to invalid evidence status
      // 500 - internal server error
      onVerified(response.status);
    } catch (err) {
      if (err instanceof AxiosError) {
        onVerified(err.response?.status ?? 500);
        return;
      }
      onVerified(500);
    } finally {
      setIsVerifying(false);
    }
  };

  if (isVerifying) {
    return <DocumentScanner />;
  }

  return (
    <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.LG} fullWidth align={Align.STRETCH}>
      <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.SM} align={Align.STRETCH}>
        <Heading>{t('addressBillVerificationScreen.heading')}</Heading>
        <Muted>
          {t('addressBillVerificationScreen.description', {
            meter: consent.meter_numbers
              ?.reduce((str, meterNumber) => str.concat(meterNumber, ', '), '')
              .slice(0, -2),
          })}
        </Muted>

        <EvidenceUpload
          consent={consent}
          evidenceType={ConsentEvidenceType.ENERGY_BILL}
          extraMetadata={{ ocr_status: { manual: true } }}
          onError={error => {
            setError(Boolean(error));
          }}
        />
      </Flex>
      <Flex gap={Gap.SM}>
        <Button
          outline
          onClick={async () => {
            await API.removeEvidences(consent.id);
            onBack();
          }}>
          {t('common:back')}
        </Button>
        <Button
          disabled={error || !ConsentStore.evidenceDocumentMetadata}
          onClick={async () => {
            await handleVerification();
          }}>
          {t('common:confirmDetails')}
        </Button>
      </Flex>
    </Flex>
  );
}

export default observer(AddressBillVerification);
