/* eslint-disable import/no-named-as-default-member */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// needed to use multiple backends
import ChainedBackend, { type ChainedBackendOptions } from 'i18next-chained-backend';
// loads locales from a URL (in our case, public/locales)
import HttpBackend, { type HttpBackendOptions } from 'i18next-http-backend';
// caching in production
// import LocalStorageBackend, { type LocalStorageBackendOptions } from 'i18next-localstorage-backend';
import { version } from '../package.json';

// Hot reload for locales
// @see vite.config.ts
if (import.meta.hot) {
  import.meta.hot.on('locales-update', () => {
    i18n
      .reloadResources()
      .then(async () => {
        await i18n.changeLanguage(i18n.language);
      })
      .catch(e => {
        console.error(e);
      });
  });
}

const isDev = import.meta.env.VITE_APP_ENV === 'dev';

/*
const cacheOptions: LocalStorageBackendOptions = {
    expirationTime: 2 * 24 * 60 * 60 * 1000, // 2 days
};
 */

const httpOptions: HttpBackendOptions = {
  loadPath: (langs, namespaces) => {
    // Abort if more than one language or namespace is requested
    if (namespaces.length > 1 || langs.length > 1) return '';
    const [namespace] = namespaces;
    // If the namespace is part of a flow, we have that in another folder
    if (namespace.includes('-flow')) {
      // flows should follow the path flows/{country}.json
      // but flow names are like {country}-flow
      // so we need to remove the -flow part from the namespace
      // and only load the country part because we could have UK flow
      // and a USA flow for example and UK and USA are not languages
      return `/locales/{{lng}}/flows/${namespace.split('-')[0]}.json`;
    }
    // Otherwise, we load the namespace from the common folder
    return '/locales/{{lng}}/{{ns}}.json';
  },
  queryStringParams: {
    v: version,
  },
};

const backendOptions = [httpOptions];

i18n
  .use(initReactI18next)
  .use(ChainedBackend)
  .init<ChainedBackendOptions>({
    partialBundledLanguages: true,
    lng: 'en',
    load: 'languageOnly',
    ns: ['common', 'validation'],
    fallbackLng: 'en',
    debug: isDev,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      backends: isDev ? [HttpBackend] : [HttpBackend], // disable cache in dev
      backendOptions,
    },
  })
  .then(() => {
    console.info('i18n initialized');
  })
  .catch(e => {
    console.error(`Could not initialize i18n due to: ${e}`);
  });

export default i18n;
