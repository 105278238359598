import { type ReactElement } from 'react';
import Card from '../Card/Card.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Button from '../../atoms/Button/Button.tsx';
import navigateToCallback from '../../../utils/navigateToCallback.ts';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import css from './ConsentAlreadyCompleted.module.scss';
import Muted from '../../atoms/Muted/Muted.tsx';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { useTranslation } from 'react-i18next';

interface ConsentAlreadyCompletedProps {
  consent: HostedConsentModel;
}

function ConsentAlreadyCompleted({ consent }: ConsentAlreadyCompletedProps): ReactElement {
  const { t } = useTranslation();
  function handleComplete(): void {
    navigateToCallback(consent, true);
  }

  return (
    <Card>
      <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.XL}>
        <div>
          <Heading>{t('common:consentCompletedScreen:heading')}</Heading>
          <Muted>
            {t('common:consentCompletedScreen:description', {
              account: consent.account.name,
            })}
          </Muted>
        </div>
        <Button className={css.fullWidth} onClick={handleComplete}>
          {t('common:returnToApp', { account: consent.account.name })}
        </Button>
      </Flex>
    </Card>
  );
}

export default ConsentAlreadyCompleted;
