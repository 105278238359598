import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { FormEvent, ReactElement, useState } from 'react';
import Button from '../../../atoms/Button/Button.tsx';
import css from './PaymentForm.module.scss';
import { StripeError } from '@stripe/stripe-js';
import Notice, { NoticeType } from '../../../atoms/Notice/Notice.tsx';
import { useTranslation } from 'react-i18next';

interface PaymentFormProps {
  postcode?: string;
}
function PaymentForm({ postcode }: PaymentFormProps): ReactElement {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | StripeError>(null);

  const handleSubmit = async (event: FormEvent): Promise<void> => {
    setLoading(true);
    setError(null);
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    await stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
          payment_method_data: {
            billing_details: {
              address: {
                /**
                 * Pre-populating the payment method data with the postcode will ensure that the card we
                 * use must have the same postcode as the one we originally chose when selecting the
                 * address.
                 */
                postal_code: postcode,
                country: 'GB',
              },
            },
          },
        },
        redirect: 'always',
      })
      .then(function (result) {
        if (result.error) {
          setError(result.error);
        }
        setLoading(false);
      });
  };

  return (
    <form className={css.paymentForm} onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          fields: {
            billingDetails: { address: { postalCode: 'never', country: 'never' } },
          },
        }}
      />
      {error && <Notice type={NoticeType.ERROR}>{error.message}</Notice>}
      <Button fullWidth disabled={!stripe || loading}>
        {t('common:confirmAddress')}
      </Button>
    </form>
  );
}

export { PaymentForm };
