import { ReactElement, useEffect, useRef, useState } from 'react';
import { DataFetching } from '../../../../animations/DataFetching/DataFetching.tsx';
import API, { type APIResponse } from '../../../../../api/API.ts';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import { AddressBox } from '../../../../atoms/AddressBox/AddressBox.tsx';
import Muted from '../../../../atoms/Muted/Muted.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import { CpuChipIcon, FireIcon } from '@heroicons/react/24/outline';
import css from './SearchForMPAN.module.scss';
import FadeIn from '../../../../animations/FadeIn/FadeIn.tsx';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { useTranslation } from 'react-i18next';
import { UKPostcoderSearchResult } from '../../../../../types/common.types.ts';
import Small from '../../../../atoms/Small/Small.tsx';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';

interface SearchForMPANProps {
  address: UKPostcoderSearchResult | null;
  consent: HostedConsentModel;
  onChangeAddress: () => void;
  onSelectMPAN: (mpans: { electricity: string | undefined; gas: string | undefined }) => void;
}
function SearchForMPAN({
  address,
  onChangeAddress,
  onSelectMPAN,
  consent,
}: SearchForMPANProps): ReactElement {
  const { t } = useTranslation('uk-flow');
  const [loading, setLoading] = useState(true);
  const [showManualMPANEntry, setShowManualMPANEntry] = useState(false);
  const [manualMPANEntry, setManualMPANEntry] = useState('');

  const [pollingRequest, setPollingRequest] = useState<null | Record<string, any>>(null);
  const [pollingAttempts, setPollingAttempts] = useState(0);
  const [ESMEDevice, setESMEDevice] = useState<null | Record<string, any>>(null);
  const [GSMEDevice, setGSMEDevice] = useState<null | Record<string, any>>(null);

  const interval = useRef<any>(null);

  function handleToggleManualMPANEntry(): void {
    setShowManualMPANEntry(true);
  }

  function handleRetry(): void {
    setESMEDevice(null);
    setPollingAttempts(0);
    setLoading(true);
  }

  const resetState = (): void => {
    setESMEDevice(null);
    setGSMEDevice(null);
    clearInterval(interval.current);
    setLoading(false);
    setShowManualMPANEntry(false);
  };

  useEffect(() => {
    interval.current = setInterval(async () => {
      if (pollingRequest != null) {
        if (pollingAttempts < 10) {
          setPollingAttempts(pollingAttempts + 1);
          await API.axios
            .get(`${import.meta.env.VITE_API}/polled-requests/${pollingRequest.id}`)
            .then(({ data }) => {
              let esme = null;
              let gsme = null;
              /**
               * Typically this will get called because most users should be searching for an MPAN using
               * the address info, they only directly search with an MPAN if we don't find anything
               * and they already know the MPAN number.
               */
              if (data.response?.crq1011_read_inventory_details_with_address_outputs) {
                Object.keys(
                  data.response.crq1011_read_inventory_details_with_address_outputs.device_list,
                ).forEach(key => {
                  const device =
                    data.response.crq1011_read_inventory_details_with_address_outputs.device_list[
                      key
                    ];
                  if (device.device_type === 'ESME') {
                    esme = device;
                  }
                  // We do communicate with GPF for data but we get MPRN from GSME device
                  if (device.device_type === 'GSME') {
                    gsme = device;
                  }
                });
              }
              /**
               * We may get a response from calling mpan info directly, if this is the case we need to do an additional check to ensure
               * the postcode of the MPAN returned is the same as what was provided by the user.
               */
              if (data.response?.crq1009_read_inventory_details_with_mpan_outputs) {
                Object.keys(
                  data.response.crq1009_read_inventory_details_with_mpan_outputs.device_list,
                ).forEach(key => {
                  const device =
                    data.response.crq1009_read_inventory_details_with_mpan_outputs.device_list[key];
                  /**
                   * In the event we've got data from a specific mpan request BUT IT DOESN'T match
                   * the postcode provided at the start of the flow, we should error out, because
                   * this implies the user has provided an MPAN for a property in a different
                   * postcode entirely.
                   */
                  if (device.post_code !== address?.postcode) {
                    setESMEDevice(null);
                    setGSMEDevice(null);
                    clearInterval(interval.current);
                    setLoading(false);
                  } else {
                    if (device.device_type === 'ESME') {
                      esme = device;
                    }
                    // We do communicate with GPF for data but we get MPRN from GSME device
                    if (device.device_type === 'GSME') {
                      gsme = device;
                    }
                  }
                });
              }

              if (esme != null) {
                setESMEDevice(esme);
                clearInterval(interval.current);
                setLoading(false);
              }
              if (gsme != null) {
                setGSMEDevice(gsme);
                clearInterval(interval.current);
                setLoading(false);
              }

              /**
               * If there IS a response BUT the crq1001 and crq1009 are both empty (meaning not data) then
               * clear out and end the interval requests early.
               */
              if (
                data.response !== null &&
                !data.response.crq1011_read_inventory_details_with_address_outputs &&
                !data.response.crq1009_read_inventory_details_with_mpan_outputs
              ) {
                setESMEDevice(null);
                setGSMEDevice(null);
                clearInterval(interval.current);
                setLoading(false);
              }
            })
            .catch(() => {
              resetState();
            });
        } else {
          resetState();
        }
      }
    }, 3000);
    return () => {
      clearInterval(interval.current);
    };
  }, [pollingRequest, pollingAttempts]);

  useEffect(() => {
    if (address) {
      API.axios
        .get<APIResponse>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/postcode-meter-lookup`,
          {
            params: {
              address_identifier: consent.contextual_data?.address_identifier,
              postcode: consent.contextual_data?.postcode,
              data_source: 'dcc',
            },
          },
        )
        .then(({ data }) => {
          if (data.success) {
            setPollingRequest(data.data);
          }
        })
        .catch(({ response }) => {
          console.error(response);
          setLoading(false);
        });
    }
  }, [address]);

  async function handleManualMeterLookup(): Promise<void> {
    API.axios
      .get<APIResponse>(
        `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/manual-meter-lookup`,
        {
          params: {
            meter_number: manualMPANEntry,
            data_source: 'dcc',
          },
        },
      )
      .then(({ data }) => {
        setShowManualMPANEntry(false);
        if (data.success) {
          handleRetry();
          setPollingRequest(data.data);
        }
      })
      .catch(({ response }) => {
        console.log(response);
        setLoading(false);
      });
  }

  if (loading) {
    return <DataFetching text="Searching for MPAN" />;
  }
  if (!address) {
    // Not translated as this should never happen
    throw new Error('Finished loading but no address selected in SearchForMPAN.tsx');
  }

  return (
    <Flex
      flexDirection={FlexDirection.COLUMN}
      align={Align.STRETCH}
      gap={Gap.LG}
      className={css.searchForMPAN}>
      <AddressBox address={address.summaryline} />
      {ESMEDevice?.mpxn_primary || GSMEDevice?.mpxn_primary ? (
        <>
          <div>
            <Heading>{t('searchForMPANScreen.heading')}</Heading>
            <Muted>{t('searchForMPANScreen.subHeading')}</Muted>
          </div>
          <FadeIn>
            {ESMEDevice && (
              <div>
                <Muted className={css.black}>Electricity (MPAN)</Muted>
                <Flex className={css.mpan} justify={Justify.START} gap={Gap.MD}>
                  <CpuChipIcon className={css.icon} />
                  <span>{ESMEDevice.mpxn_primary}</span>
                </Flex>
              </div>
            )}
            {GSMEDevice && (
              <div className={css.second}>
                <Muted className={css.black}>Gas (MPRN)</Muted>
                <Flex className={css.mpan} justify={Justify.START} gap={Gap.MD}>
                  <FireIcon className={css.icon} />
                  <span>{GSMEDevice.mpxn_primary}</span>
                </Flex>
              </div>
            )}
          </FadeIn>
          <Button
            className={css.selectMPAN}
            onClick={() => {
              onSelectMPAN({
                electricity: ESMEDevice?.mpxn_primary,
                gas: GSMEDevice?.mpxn_primary,
              });
            }}>
            {t('searchForMPANScreen.confirmation')}
          </Button>
        </>
      ) : (
        <>
          {showManualMPANEntry ? (
            <>
              <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD} align={Align.START}>
                <Heading>{t('searchForMPANScreen.manualInput.heading')}</Heading>
                <TextInput
                  label={t('searchForMPANScreen.manualInput.label')}
                  value={manualMPANEntry}
                  onChange={e => {
                    setManualMPANEntry(e.target.value);
                  }}
                />
                <Button disabled={manualMPANEntry.length === 0} onClick={handleManualMeterLookup}>
                  {t('common:search')}
                </Button>
              </Flex>
            </>
          ) : (
            <>
              <Heading error>{t('searchForMPANScreen.errorHeading')}</Heading>
              <Muted>{t('searchForMPANScreen.errorSubHeading')}</Muted>
              <Flex gap={Gap.MD}>
                <Button outline onClick={onChangeAddress}>
                  {t('common:changeAddress')}
                </Button>
                <Button onClick={handleRetry}>{t('common:retry')}</Button>
              </Flex>
              <Small className={css.manualPrompt}>
                <div onClick={handleToggleManualMPANEntry}>
                  {t('searchForMPANScreen.manualInput.inputPrompt')}
                </div>
              </Small>
            </>
          )}
        </>
      )}
    </Flex>
  );
}

export { SearchForMPAN };
