import { type ReactElement } from 'react';
import { Route, Switch } from 'wouter';
import ConsentFlow from './components/pages/ConsentFlow/ConsentFlow.tsx';
import DeadEnd from './components/pages/DeadEnd/DeadEnd.tsx';
import { ErrorBoundary } from 'react-error-boundary';
import ApplicationError from './components/pages/ApplicationError/ApplicationError.tsx';

function App(): ReactElement {
  return (
    <ErrorBoundary fallback={<ApplicationError />}>
      <Switch>
        <Route path="/:id">
          <ConsentFlow />
        </Route>
        <Route>
          <DeadEnd />
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default App;
