import { type ReactElement } from 'react';
import css from './ConsentSteps.module.scss';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Small from '../../atoms/Small/Small.tsx';
import { useTranslation } from 'react-i18next';

export interface ConsentStep {
  text: string;
  value: string;
}

interface ConsentStepsProps {
  steps: ConsentStep[];
  current: ConsentStep;
}

function ConsentSteps({ steps, current }: ConsentStepsProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Flex className={css.consentSteps} gap={Gap.MD}>
      {steps.map((step, index) => {
        const isDone = steps.findIndex(s => s.value === current.value) > index;
        return (
          <Flex
            key={step.value}
            className={`
                                ${css.step}
                                ${current.value === step.value ? css.current : ''}
                                ${isDone ? css.completed : ''}
                            `}
            flexDirection={FlexDirection.COLUMN}
            align={Align.START}>
            <Small>
              {t('common:step').toUpperCase()} {index + 1}
            </Small>
            <span className={css.title}>{step.text}</span>
          </Flex>
        );
      })}
    </Flex>
  );
}

export default ConsentSteps;
