import css from './ScreenTransition.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import type { PropsWithChildren, ReactElement } from 'react';

interface ScreenTransitionProps {
  screen: string;
}

function ScreenTransition({
  children,
  screen,
}: PropsWithChildren<ScreenTransitionProps>): ReactElement {
  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={screen}
        className={css.animated}
        initial={{
          opacity: 0,
          transform: 'translateX(20px)',
        }}
        animate={{
          opacity: 1,
          transform: 'translateX(0px)',
        }}
        exit={{
          opacity: 0,
          transform: 'translateX(-20px)',
        }}
        transition={{
          duration: 0.5,
        }}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
}

export { ScreenTransition };
