import { ReactElement } from 'react';
import { DataFetching } from '../../animations/DataFetching/DataFetching';
import Flex, { Align, Justify } from '../../atoms/Flex/Flex';
import css from './SelectableGrid.module.scss';
import { Chip } from '../../atoms/Chip/Chip';
import { useTranslation } from 'react-i18next';

export interface SelectableGridItem {
  id: string;
  image: string;
  enabled: boolean;
  disabledChipText?: string;
}

export interface SelectableGridProps {
  items: SelectableGridItem[];
  showDisabledChip?: boolean;
  disabledChipText?: string;
  onSelect: (item: SelectableGridItem) => void;
  loading?: boolean;
}

export function SelectableGrid({
  items,
  onSelect,
  loading = false,
  showDisabledChip = true,
  disabledChipText = '',
}: SelectableGridProps): ReactElement {
  const { t } = useTranslation();
  disabledChipText = disabledChipText || t('common:comingSoon');
  return (
    <div className={`${css.selectableGrid}`}>
      {loading ? (
        <DataFetching text={t('common:loadingOperator')} className={css.loadingSpinner} />
      ) : null}
      {items.map(item => (
        <Flex
          align={Align.CENTER}
          justify={Justify.CENTER}
          className={`${css.gridCard} ${item.enabled && !loading ? css.enabled : css.disabled}`}
          key={item.id}
          onClick={
            item.enabled
              ? () => {
                  onSelect(item);
                }
              : () => {}
          }>
          <img className={css.gridCardImage} src={item.image} alt={item.id} />
          {!item.enabled && showDisabledChip ? (
            <Chip
              text={item.disabledChipText ? item.disabledChipText : disabledChipText}
              fontSize="11px"
              className={css.disabledChip}
            />
          ) : null}
        </Flex>
      ))}
    </div>
  );
}
