import type { PropsWithChildren, ReactElement } from 'react';
import css from './ResultsBox.module.scss';
import Flex, { Align, FlexDirection, Justify } from '../../atoms/Flex/Flex.tsx';

interface ResultsBoxProps {
  className?: string;
}
function ResultsBox({
  children,
  className = '',
}: PropsWithChildren<ResultsBoxProps>): ReactElement {
  return (
    <Flex
      className={`${css.resultsBox} ${className}`}
      flexDirection={FlexDirection.COLUMN}
      align={Align.STRETCH}
      justify={Justify.START}>
      {children}
    </Flex>
  );
}

export { ResultsBox };
