import { observer } from 'mobx-react-lite';
import { useContext, useState, type ReactElement } from 'react';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import { type ConsentStep } from '../../molecules/ConsentSteps/ConsentSteps.tsx';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import { Completed } from '../../molecules/ConsentCompleted/Completed.tsx';
import PersonInfoCollection from './flow-specific/shared/PersonInfo/PersonInfo.tsx';
import AppContext from '../../../store/AppContext.ts';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';
import { useTranslation } from 'react-i18next';
import DatadisConnection from './flow-specific/shared/DatadisConnection/DatadisConnection.tsx';

enum ConsentFlowScreens {
  START = 'start',
  INFO_COLLECTION = 'info-collection',
  DATADIS_SELECTION = 'datadis-selection',
  COMPLETED = 'completed',
}

export interface FlowProps {
  consent: HostedConsentModel;
}

export const FlowSteps: ConsentStep[] = [
  { text: 'Get info', value: 'info' },
  { text: 'Connect Datadis', value: 'datadis' },
];

function ESResidentialSpecific({ consent }: FlowProps): ReactElement {
  const { t } = useTranslation('es-flow');
  const isReturningUser = consent.contextual_data?.pending_account_creation;
  // This has to be done like this because we are using the same component
  // on the operator selection and the EANCollection screen
  FlowSteps[0].text = t('common.step1');
  FlowSteps[1].text = t('common.step2');
  // If the user is returning from the previous account creation flow, we skip the postcode collection
  // and land them directly in the Datadis selection screen
  const [screen, setScreen] = useState<ConsentFlowScreens>(
    isReturningUser ? ConsentFlowScreens.DATADIS_SELECTION : ConsentFlowScreens.START,
  );
  const { LanguageStore } = useContext(AppContext);
  LanguageStore.setAvailableLanguages([Languages.ES, Languages.EN]);
  LanguageStore.setDefaultLanguage(Languages.ES);

  function renderScreen(): ReactElement {
    if (consent != null) {
      switch (screen) {
        case ConsentFlowScreens.START:
          return (
            <StartScreen
              logo={consent.account.logo}
              logoMetadata={consent.account.logo_metadata ? consent.account.logo_metadata : null}
              partner={consent.account.name}
              onNext={() => {
                setScreen(ConsentFlowScreens.INFO_COLLECTION);
              }}
            />
          );
        case ConsentFlowScreens.INFO_COLLECTION:
          return (
            <PersonInfoCollection
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.DATADIS_SELECTION);
              }}
            />
          );
        case ConsentFlowScreens.DATADIS_SELECTION:
          return (
            <DatadisConnection
              consent={consent}
              onNext={() => {
                setScreen(ConsentFlowScreens.COMPLETED);
              }}
            />
          );
        case ConsentFlowScreens.COMPLETED:
          return <Completed consent={consent} />;
      }
    }
    return <></>;
  }
  return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(ESResidentialSpecific);
