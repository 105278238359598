import React, { type ReactElement, useContext, useState } from 'react';
import Card from '../../../../molecules/Card/Card.tsx';
import css from './LoaConsentScreen.module.scss';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import EvidenceUpload from '../../../../organisms/EvidenceUpload/EvidenceUpload.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import ConsentExpiry from '../../../../organisms/ConsentExpiry/ConsentExpiry.tsx';
import PlatformTermsCheckbox from '../../../../organisms/PlatformTermsCheckbox/PlatformTermsCheckbox.tsx';
import PrivacyText from '../../../../atoms/PrivacyText/PrivacyText.tsx';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';
import API from '../../../../../api/API.ts';
import Loading from '../../../../atoms/Loading/Loading.tsx';
import Notice, { NoticeType } from '../../../../atoms/Notice/Notice.tsx';
import AppContext from '../../../../../store/AppContext.ts';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { AccountModel } from '../../../../../types/account.types.ts';
import { validateEmail } from '../../../../../utils/validateEmail.ts';
import { useTranslation } from 'react-i18next';
import CollapsibleNotice from '../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';

interface LoaConsentScreenProps {
  consent: HostedConsentModel;
  evidenceDocument: string | null;
  onNext: () => void;
  onBack: () => void;
  termsChecked: boolean;
  account?: AccountModel;
}
function LoaConsentScreen({
  consent,
  onNext,
  onBack,
  evidenceDocument,
  termsChecked,
  account,
}: LoaConsentScreenProps): ReactElement {
  const { t } = useTranslation('uk-flow');
  const { ConsentStore } = useContext(AppContext);
  const [email, setEmail] = useState(consent.consentee_email ?? '');

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);

  function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
    setEmail(e.target.value);
  }

  async function handleSignConsent(): Promise<void> {
    await API.axios
      .put<{ success: boolean; data: HostedConsentModel }>(
        `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}`,
        {
          consentee_email: email,
        },
      )
      .then(res => {
        ConsentStore.setConsent(res.data.data);
      })
      .catch(error => {
        setError(error.response.data.errors[0].message);
        setLoading(false);
      });
    if (consent.flow_type.includes('commercial')) {
      await API.axios
        .post<{ success: boolean; data: HostedConsentModel }>(
          `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/approve`,
          {
            hosted_consent_version: APP_VERSION,
          },
        )
        .catch(error => {
          setError(error.response.data.errors[0].message);
          setLoading(false);
        });
    }
    onNext();
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <Card account={account}>
      <Flex
        className={css.loaConsentScreen}
        flexDirection={FlexDirection.COLUMN}
        align={Align.STRETCH}
        gap={Gap.LG}>
        <Heading>{t('commercialBlanketFlow.methods.loa.upload')}</Heading>
        <Flex gap={Gap.MD} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
          <EvidenceUpload consent={consent} />
          <TextInput
            label="Email"
            value={email}
            onChange={handleChangeEmail}
            disabled={consent.consentee_email ? consent.consentee_email.length > 0 : false}
          />
          <ConsentExpiry consent={consent} />
          <CollapsibleNotice
            title={t('commercialBlanketFlow.methods.loa.termsHeading')}
            iconOverride={<PlatformTermsCheckbox noContent />}>
            <>
              {t('commercialBlanketFlow.methods.loa.termsText', {
                partner: consent.account.name,
              })}
            </>
          </CollapsibleNotice>
          {error != null && <Notice type={NoticeType.ERROR}>{error}</Notice>}
        </Flex>
        <Flex gap={Gap.LG}>
          <Button
            outline
            onClick={() => {
              onBack();
            }}>
            {t('common:back')}
          </Button>
          <Button
            disabled={evidenceDocument == null || !termsChecked || !validateEmail(email)}
            onClick={handleSignConsent}>
            {t('common:next')}
          </Button>
        </Flex>
        <PrivacyText />
      </Flex>
    </Card>
  );
}

export default LoaConsentScreen;
