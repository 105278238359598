import { type PropsWithChildren, type ReactElement } from 'react';
import css from './Notice.module.scss';

export enum NoticeType {
  INFO = 'info',
  ERROR = 'error',
  SUCCESS = 'success',
}

interface NoticeProps {
  className?: string;
  type?: NoticeType;
}

function Notice({
  children,
  className,
  type = NoticeType.INFO,
}: PropsWithChildren<NoticeProps>): ReactElement {
  return <div className={`${css.notice} ${className} ${css[`type_${type}`]}`}>{children}</div>;
}

export default Notice;
