import { ChangeEvent, useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { PostcoderSearchResult } from '../../../../../../types/common.types.ts';
import { CountriesEnum } from '../../../../../../types/countries.types.ts';
import {
  EnedisMeteringPoint,
  FRContextualData,
} from '../../../../../../types/country-specific/france.types.ts';
import {
  FranceConsentSpecificConfiguration,
  HostedConsentModel,
} from '../../../../../../types/hosted-consent.types.ts';
import { DataFetching } from '../../../../../animations/DataFetching/DataFetching.tsx';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import CollapsibleNotice from '../../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../../atoms/Muted/Muted.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { PostcodeSearch } from '../../../../../organisms/PostcodeSearch/PostcodeSearch.tsx';
import { FlowSteps } from '../../../fr.residential.specific.tsx';
import { SearchForPRM } from '../SearchForPRM/SearchForPRM.tsx';
import css from './PRMCollection.module.scss';

export enum ComponentSteps {
  DATA_COLLECTION = 'data_collection',
  PRM_COLLECTION = 'prm_collection',
}

export interface FRPersonalData {
  first_name?: string;
  last_name?: string;
  // email?: string;
  // phone_number?: string;
}

interface ComponentProps {
  consent: HostedConsentModel<FRContextualData, FranceConsentSpecificConfiguration>;
  onNext: () => void;
}

function PRMCollection({ consent, onNext }: ComponentProps): ReactElement {
  // #region State
  const { ConsentStore } = useContext(AppContext);
  const [errors, setErrors] = useState<null | string | string[]>(null);
  const [step, setStep] = useState(ComponentSteps.DATA_COLLECTION);
  const { t } = useTranslation('fr-flow');

  /**
   * Stuff the user provides or clicks on.
   */
  const [personalData, setPersonalData] = useState<FRPersonalData>({});
  const [selectedAddress, setSelectedAddress] = useState<PostcoderSearchResult>();

  // #endregion

  // #region Handlers
  function handleInput(key: keyof FRPersonalData) {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      // NOTE: Removed along with the phone number but kept since we are not sure if we won't need it in the future
      // if (key === 'email') {
      //     if (value.length > 0 && !z.string().email().safeParse(value).success) {
      //         setErrors(t('validation:general.invalidEmail'));
      //     } else {
      //         setErrors(null);
      //     }
      // }

      if (personalData === null) {
        setPersonalData({ [key]: value } satisfies FRPersonalData);
        return;
      }

      setPersonalData({ ...personalData, [key]: value });
    };
  }

  async function saveSelectedAddress(): Promise<void> {
    try {
      const { data: apiResponse } = await API.updateConsent(consent.id, {
        contextual_data: {
          ...consent.contextual_data,
          meter_address: selectedAddress,
        },
        consentee_full_name: `${personalData.first_name} ${personalData.last_name}`,
        // consentee_email: personalData.email,
      });
      ConsentStore.setConsent(apiResponse.data);
    } catch (e: any) {
      setErrors(e);
    }
  }

  async function handleNext(): Promise<void> {
    await saveSelectedAddress();
    setStep(ComponentSteps.PRM_COLLECTION);
  }

  async function handleUpdateMeterNumber(
    foundMeters: EnedisMeteringPoint[],
    selectedMeters: string[],
    isManual: boolean,
  ): Promise<void> {
    try {
      const res = await API.updateConsent(consent.id, {
        meter_numbers: selectedMeters,
        contextual_data: {
          ...consent.contextual_data,
          found_meter_numbers: foundMeters,
          manual_input: isManual,
        },
      });
      ConsentStore.setConsent(res.data.data);
      onNext();
    } catch (e: any) {
      setErrors(e);
    }
  }

  // #endregion

  function renderStep(): ReactElement {
    switch (step) {
      case ComponentSteps.DATA_COLLECTION:
        return (
          <>
            <Flex
              className={css.formWrapper}
              gap={Gap.MD}
              flexDirection={FlexDirection.COLUMN}
              align={Align.STRETCH}>
              <Heading>{t('fr-flow:prmCollectionStep.heading')}</Heading>
              <Muted>{t('fr-flow:prmCollectionStep.subHeading')}</Muted>
              {errors != null && <ErrorNotice errors={errors} />}
              <div className={css.postcodeSearch}>
                <Flex gap={Gap.SM} flexDirection={FlexDirection.ROW}>
                  <TextInput
                    autoFocus
                    label={t('common:firstName')}
                    type="text"
                    onChange={handleInput('first_name')}
                    value={personalData?.first_name ?? ''}
                  />
                  <TextInput
                    label={t('common:lastName')}
                    type="text"
                    onChange={handleInput('last_name')}
                    value={personalData?.last_name ?? ''}
                  />
                </Flex>
                {/* NOTE: This is commentend because we are not sure if we won't need phone and email in the future */}
                {/* <TextInput */}
                {/*     label={t('common:email')} */}
                {/*     type="email" */}
                {/*     onChange={handleInput('email')} */}
                {/*     value={personalData?.email ?? ''} */}
                {/* /> */}
                {/* <PhoneInput */}
                {/*     label={t('common:phoneNumber')} */}
                {/*     onChange={handleInput('phone_number')} */}
                {/*     initialSelectedCountry="FR" */}
                {/*     onValidChange={(isValid, ref) => { */}
                {/*         if (isValid || ref.value.length === 0) { */}
                {/*             setErrors(null); */}
                {/*             return; */}
                {/*         } */}
                {/**/}
                {/*         setErrors(t('validation:general.invalidPhoneNumber')); */}
                {/*     }} */}
                {/*     value={personalData?.phone_number ?? ''} */}
                {/* /> */}

                <div>
                  <PostcodeSearch
                    country={CountriesEnum.FR}
                    consent={consent}
                    label={t('common:address')}
                    initialValue={selectedAddress?.summaryline ?? ''}
                    onSelectAddress={async (address: PostcoderSearchResult) => {
                      setSelectedAddress(address);
                    }}
                    onError={err => {
                      console.log(`Error: ${JSON.stringify(err, null, 2)}`);
                    }}
                    onLoading={state => {
                      console.log(`Loading: ${state}`);
                    }}
                  />
                </div>

                <Button
                  fullWidth
                  onClick={handleNext}
                  disabled={
                    !Object.keys(selectedAddress ?? {}).length ||
                    errors !== null ||
                    !Object.values(personalData).every(Boolean)
                  }>
                  {t('fr-flow:prmCollectionStep.searchPRM')}
                </Button>
              </div>
              <CollapsibleNotice
                type="info"
                title={t('fr-flow:prmCollectionStep.whatIsPRMNotice.heading')}>
                <p>{t('fr-flow:prmCollectionStep.whatIsPRMNotice.description')}</p>
              </CollapsibleNotice>
            </Flex>
          </>
        );
      case ComponentSteps.PRM_COLLECTION:
        return selectedAddress ? (
          <SearchForPRM
            personalData={personalData as Required<FRPersonalData>}
            address={selectedAddress}
            onChangeAddress={() => {
              setStep(ComponentSteps.DATA_COLLECTION);
            }}
            onSelect={async (
              found: EnedisMeteringPoint[],
              selected: string[],
              isManual: boolean,
            ) => {
              await handleUpdateMeterNumber(found, selected, isManual);
            }}
          />
        ) : (
          <></>
        );
      default:
        return <DataFetching text={t('common:fetchingData')} />;
    }
  }

  return (
    <Card account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <ConsentSteps steps={FlowSteps} current={FlowSteps[0]} />
        <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
      </Flex>
    </Card>
  );
}

export default PRMCollection;
