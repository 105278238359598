import { ChangeEvent, useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { FlowSteps } from '../../../es.residential.specific.tsx';
import css from './PersonInfo.module.scss';
import { AddressBox } from '../../../../../atoms/AddressBox/AddressBox.tsx';
import { SelectInput } from '../../../../../atoms/SelectInput/SelectInput.tsx';

export enum Steps {
  INFO_COLLECTION = 'info_collection',
  INFO_CONFIRM = 'info_confirm',
}

interface PersonInfoProps {
  consent: HostedConsentModel;
  onNext: () => void;
}

interface ESPersonInfo {
  full_name?: string;
  cups?: string;
  dni?: string;
  nie?: string;
}

/*
 * The DNI is 9 characters in the format NNNNNNNN-A.
 * The last digit is a checksum letter (checksum not verified by this).
 * e.g. 74362315-A
 */
const dniRegex = /^[0-9]{8}[A-Z]{1}$/;

/*
 * The NIE is 9 characters in the format A-NNNNNNNA where the first character
 * is either X, Y or Z and last character is a checksum letter. It is issued
 * by the Ministry of Internal Affairs to everyone who is not a Spanish citizen.
 * e.g. X-2482300W
 * NOTE: I kept the first character a bit less strict in case of spec updates.
 */
const nieRegex = /^[A-Z][0-9]{7}[A-Z]{1}$/;

/*
 * CUPS The CUPS is the Universal Supply Point Code
 * a 20 or 22 digit alphanumeric code that uniquely and permanently identifies
 * all electricity supply points.
 * e.g. ES0022123456781234ABOF
 */
const cupsRegex = /^ES[A-Z0-9]{18,20}$/;

function PersonInfoCollection({ consent, onNext }: PersonInfoProps): ReactElement {
  // #region State
  const [error, setError] = useState<null | string>(null);
  const [step, setStep] = useState(Steps.INFO_COLLECTION);
  const { t } = useTranslation('es-flow');
  const [taxDocument, setTaxDocument] = useState<string | undefined>(undefined);

  /**
   * Stuff the user provides or clicks on.
   */
  const [personInfo, setPersonInfo] = useState<ESPersonInfo>({});

  const { ConsentStore } = useContext(AppContext);
  // #endregion

  // #region Handlers
  function handleInput(key: keyof ESPersonInfo) {
    return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
      let parsedValue = value;
      let error = null;

      if (key === 'dni') {
        parsedValue = value.toUpperCase().replace(/\s+/g, '').substring(0, 9);
      }

      if (key === 'nie') {
        parsedValue = value.toUpperCase().replace(/\s+/g, '').substring(0, 9);
      }

      if (key === 'cups') {
        parsedValue = value.toUpperCase().replace(/\s+/g, '').substring(0, 22);
      }

      const newPersonInfo: ESPersonInfo = { ...personInfo, [key]: parsedValue };

      if (newPersonInfo?.dni && !dniRegex.test(newPersonInfo?.dni)) {
        error = t('es-flow:errors.dniFormat');
      }
      if (newPersonInfo?.nie && !nieRegex.test(newPersonInfo?.nie)) {
        error = t('es-flow:errors.nieFormat');
      }
      if (newPersonInfo?.cups && !cupsRegex.test(newPersonInfo.cups)) {
        error = t('es-flow:errors.cupsFormat');
      }

      setError(error);
      setPersonInfo(newPersonInfo);
    };
  }
  async function handleFillPersonInfo(): Promise<void> {
    try {
      const { data: apiResponse } = await API.updateAuthorizationData(consent.id, {
        authorization_data: {
          nie: personInfo.nie,
          dni: personInfo.dni,
          full_name: personInfo.full_name,
        },
      });
      ConsentStore.setConsent({
        ...consent,
        authorization_data: apiResponse.authorization_data,
      });
      const res = await API.updateConsent(consent.id, {
        contextual_data: {
          ...consent.contextual_data,
          meter_numbers: [personInfo.cups],
          manual_input: true,
        },
      });
      ConsentStore.setConsent(res.data.data);
      setStep(Steps.INFO_CONFIRM);
    } catch (e: any) {
      setError(e);
    }
  }

  function handleConfirm(): void {
    onNext();
  }

  function handleBack(): void {
    setStep(Steps.INFO_COLLECTION);
  }

  function handleChangeTaxDocument(value: string | undefined): void {
    if (value !== 'dni') personInfo.dni = '';
    if (value !== 'nie') personInfo.nie = '';
    setTaxDocument(value);
  }

  // #endregion

  function renderStep(): ReactElement {
    switch (step) {
      case Steps.INFO_COLLECTION:
        return (
          <Flex
            className={css.personInfo}
            gap={Gap.MD}
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}>
            <Heading>{t('es-flow:residentialFlow:collectionStep.heading')}</Heading>
            {error != null && <ErrorNotice errors={error} />}
            <div className={css.infoBox}>
              <TextInput
                label={t('common:fullName')}
                type="text"
                className={css.fullNameField}
                onChange={handleInput('full_name')}
                value={personInfo?.full_name ?? ''}
              />
              <TextInput
                label={t('es-flow:residentialFlow:collectionStep.cupsLabel')}
                type="text"
                placeholder={'ESXXXXXXXXXXXXAB1P'}
                className={css.cupsField}
                onChange={handleInput('cups')}
                value={personInfo?.cups ?? ''}
              />
              <SelectInput
                value={taxDocument}
                className={css.selectTaxDocument}
                options={[
                  { value: 'dni', text: t('es-flow:residentialFlow.collectionStep.dniLabel') },
                  { value: 'nie', text: t('es-flow:residentialFlow.collectionStep.nieLabel') },
                ]}
                onChange={handleChangeTaxDocument}
              />
              {taxDocument === 'nie' && (
                <TextInput
                  label={t('es-flow:residentialFlow:collectionStep.nieLabel')}
                  placeholder={'A1234567B'}
                  value={personInfo.nie ?? ''}
                  onChange={handleInput('nie')}
                />
              )}
              {taxDocument === 'dni' && (
                <TextInput
                  label={t('es-flow:residentialFlow:collectionStep.dniLabel')}
                  placeholder={'12345678A'}
                  value={personInfo.dni ?? ''}
                  onChange={handleInput('dni')}
                />
              )}
              <Button
                fullWidth={true}
                onClick={handleFillPersonInfo}
                disabled={
                  (!personInfo.dni && !personInfo.nie) ||
                  !personInfo.full_name ||
                  !personInfo.cups ||
                  !!error
                }>
                {t('common:next')}
              </Button>
            </div>
          </Flex>
        );
      case Steps.INFO_CONFIRM:
        return (
          <Flex
            className={css.infoConfirm}
            gap={Gap.MD}
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}>
            <Heading>{t('es-flow:residentialFlow:confirmStep.heading')}</Heading>
            {error != null && <ErrorNotice errors={error} />}
            <div className={css.infoBox}>
              <AddressBox
                address={`${personInfo?.full_name}, ${taxDocument === 'dni' ? `DNI: ${personInfo.dni}` : `NIE: ${personInfo.nie}`}, CUPS: ${personInfo?.cups}`}
              />
            </div>
            <Flex flexDirection={FlexDirection.ROW} gap={Gap.SM}>
              <Button outline={true} fullWidth={true} onClick={handleBack}>
                {t('common:back')}
              </Button>
              <Button fullWidth={true} onClick={handleConfirm}>
                {t('common:confirm')}
              </Button>
            </Flex>
          </Flex>
        );
      default:
        return <></>;
    }
  }

  return (
    <Card account={consent.account}>
      <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
        <ConsentSteps steps={FlowSteps} current={FlowSteps[0]} />
        <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
      </Flex>
    </Card>
  );
}

export default PersonInfoCollection;
