import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Button from '../../atoms/Button/Button.tsx';
import css from '../EmailVerificationScreen/EmailVerificationScreen.module.scss';
import navigateToCallback from '../../../utils/navigateToCallback.ts';
import { type ReactElement } from 'react';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { useTranslation } from 'react-i18next';

interface EmailVerificationSuccessProps {
  consent: HostedConsentModel;
  isThirdParty?: boolean;
}

function EmailVerificationSuccess({
  consent,
  isThirdParty = false,
}: EmailVerificationSuccessProps): ReactElement {
  const { t } = useTranslation();
  return (
    <Flex flexDirection={FlexDirection.COLUMN} align={Align.STRETCH} gap={Gap.XL}>
      <Heading>{t('common:emailVerificationScreen.heading')}</Heading>
      {isThirdParty ? (
        <span>{t('common:emailVerificationScreen.thirdParty')}</span>
      ) : (
        <Button
          className={css.fullWidth}
          onClick={() => {
            navigateToCallback(consent, true);
          }}>
          {t('common:returnToApp', { account: consent.account.name })}
        </Button>
      )}
    </Flex>
  );
}

export default EmailVerificationSuccess;
