import { ReactElement } from 'react';

export interface ChipProps {
  text: string;
  color?: string;
  fontSize?: string;
  textColor?: string;
  className?: string;
}

export function Chip(props: ChipProps): ReactElement {
  return (
    <div
      className={props.className}
      style={{
        border: `1px solid ${props.color ? props.color : 'var(--colour-nevis)'}`,
        color: `${props.color ? props.color : 'var(--colour-scafell)'}`,
        padding: '5px 10px',
        borderRadius: '20px',
        margin: '0 5px',
        fontSize: props.fontSize ? props.fontSize : '12px',
        whiteSpace: 'nowrap',
      }}>
      {props.text}
    </div>
  );
}
